@import "vars_and_mixins.scss";

@keyframes bounceAlpha {
  0% {transform: translateY(0px) }
  25%{transform: translateY(100%) }
  26%{transform: translateY(-100%)}
  55%{transform: translateY(0px)}
}

.menu{
  @include z-index(menu);
  position: fixed;
  top: 0px;
  right: 0px;
  height: 50px;
  width: 50%;
  transition: all .5s;

  &.show{
     transform: translateY(0);
  }

  @include breakpoint(md) {
     left: 0px;
     width: 100%;
  }

  @include breakpoint(lg) {
     &.hidden{
        transform: translateY(-100%);
     }
  }

  .backBlock{
     display: flex;
     justify-content: center;
     align-items: center;

     svg{
        width: 12px;
        fill: white;
        margin-right: 10px;
        transform: rotate(-180deg) translateY(2px);
        fill: #006372;

        @include breakpoint(lg) {
           fill: white;
        }
     }
  }

  .horizontal-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    width: 100%;
    padding: 7px 20px 4px;
    z-index: 1;

    @include breakpoint(lg) {
       background-color: $yellow;
    }

    .edition{
      display: none;

      margin-top: 0px;
      @include font-format('menu_date');

      color: #006372;
      font-weight: 700;

      @include breakpoint(lg) {
         display: block;
         font-weight: 400;
         color: $white;
      }
      padding-top: 6px;
    }

    .buttons{
      margin-left: auto;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 0;
      display: flex;
      align-items: center;
      //background-color: rgba(#006372, 1);
      padding-left: 25px;

      // @include breakpoint(lg) {
      //    background-color: transparent;
      // }
    }
    .burger{

      //display: none;
      padding: 0;
      border: 0;
      z-index: 3;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .burgersvg{
         width: 50px;
        .cls-1{fill: #006372; }
        .cls-2,.cls-3,.cls-4{ transform-origin: center; fill:none;stroke: $white; stroke-width:2px; transition: all .4s;}
      }


      @include breakpoint(lg) {
         .burgersvg{
           .cls-1{fill: none;}
         }
      }
    }


    .lang{
      margin-right: 20px;
      margin-top: 4px;
      display: none;
      // @include breakpoint(md) {
      //   margin-right: 80px;
      // }
      @include breakpoint(lg) {
         display: block;
      }
      color: white;


      .label:first-of-type{
        margin-right: 10px;
      }

      .label:last-child{
        margin-left: 10px;
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 22px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: .4s;
      background-image: linear-gradient(to left, $green 0%, #006372 100%);
      border: solid 1px white;

      color: $green;

      &:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 28px;
        left: 0px;
        bottom: -1px;
        background-color: white;
        transition: .4s;
      }

      &.round {
        border-radius: 22px;
      }

      &.round:before {
        border-radius: 22px;
      }
    }

    input:checked + .slider:before {
      transform: translateX(12px);
    }
    // ul{
    //   display: none;

    //   @include breakpoint(lg) {
    //     display: flex;
    //     flex-direction: row;
    //     list-style: none;
    //     padding: 0;
    //     align-items: center;
    //     justify-content: space-between;

    //     margin: 0;
    //     width: 100%;
    //     height: 100%;
    //   }

    //   li{
    //     padding: 0;
    //     text-transform: uppercase;
    //     margin: 0 15px;
    //     font-size: 16px;
    //     line-height: 20px;

    //     .btn{
    //       @include font-format('orizmenu');
    //       font-weight: 400;

    //       color: $white;
    //       padding: 0;
    //       margin: 0;
    //       background: none;


    //       &:focus {
    //         outline: none;
    //         box-shadow: none;
    //       }
    //     }
    //   }
    // }
  }

  .menuContainer{
    background: #006372;
    position: fixed;
    top: 0px;
    right: 0px;
    height: calc(100vh - 0px);
    //width: 100%;
    padding: 20px;
    z-index: 2;
    min-width: 340px;
    transform: translateX(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .4s;
    flex-direction: column;

    .lang{
      margin-right: 20px;
      margin-bottom: 14px;

      display: block;
      // @include breakpoint(md) {
      //   margin-right: 80px;
      // }
      @include breakpoint(lg) {
         display: none;
      }
      color: white;


      .label:first-of-type{
        margin-right: 10px;
      }

      .label:last-child{
        margin-left: 10px;
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 22px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: .4s;
      background-image: linear-gradient(to left, $green 0%, #006372 100%);
      border: solid 1px white;

      color: $green;

      &:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 28px;
        left: 0px;
        bottom: -1px;
        background-color: white;
        transition: .4s;
      }

      &.round {
        border-radius: 22px;
      }

      &.round:before {
        border-radius: 22px;
      }
    }

    input:checked + .slider:before {
      transform: translateX(12px);
    }

    ul{
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      margin: 0;
      min-width: 250px;

      li{
        margin: 0px;
        padding: 10px 0 10px;
        border-bottom: solid 1px $white;

        .btn{
          font-size: $font-size-xs;
          line-height: 1.2em;
          font-weight: $font-normal;

          text-transform: uppercase;

          @include breakpoint(md) {
            font-size: $font-size-s;
          }

          text-align: left;
          color: $white;
          padding: 0;
          margin: 0;
          background: none;


          &:focus {
            outline: none;
            box-shadow: none;
          }

          &:hover{
            color: $grey;
          }
        }

        body.body_idea &.menu_idea,
        body.body_campus &.menu_campus,
        body.body_talk &.menu_talk,
        body.body_projects &.menu_projects,
        body.body_zoner &.menu_zoner{
          .btn{
            color: $grey;
            font-weight: $font-strong;
          }
        }

        &:last-child{
          border-bottom: 0;
        }
      }
    }


    .social{
      margin-top: 20px;

      a.download{
        margin: 5px 0 25px 0px;
        color: $white;
        @include font-format('follow');
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        text-decoration: none;

        span{
          border-bottom: 1px solid $white;
        }

        .downloadSvg{
          width: 18px;
          margin: 0 6px 0px 0;

          .downloadPathSvg{
            fill: $white;
          }
        }

        &:hover{
          .downloadSvg{
            .downloadArrowSvg{
              animation-name: bounceAlpha;
              animation-duration:1.4s;
              animation-iteration-count:infinite;
              animation-timing-function:linear;
            }
          }
        }
      }

      .videoLink{
        margin-bottom: 5px;
        color: $white;
        text-decoration: none;
        display: inline-block;
        @include font-format('follow');

        @media screen and ( max-height: 570px ){
          display: none;
        }


        span{
          border-bottom: 1px solid $white;
        }

        .playSvg{
          width: 23px;
          margin: 0 6px 0px 0;

          .playPathSvg{
            fill: #006372;
          }
        }
      }

      .socialSvg{
        width: 28px;
        margin-right: 7px;

        .socialPath{fill:$white;}

        &:hover{
          .socialPath{fill:$green;}
        }
      }
    }
  }

  &.sticky, &.nohome{
    overflow: visible;

    // .horizontal-menu{
    //   position: fixed;
    //   top: 0;

    //   ul{
    //     display: none;
    //   }
    //   .burger{
    //     display: block;
    //   }
    //   .logo{
    //     display: block;
    //   }
    // }
  }

  &.open{ //menuContainer
    .menuContainer{
      transform: translateX(0%);
    }

    .horizontal-menu{
      .burger{
        .burgersvg{
          .cls-3{opacity: 0;}
          .cls-2{ transform: rotate(45deg) translateY(7px)}
          .cls-4{ transform: rotate(-45deg) translateY(-8px)}

        }
      }
    }
  }

}
