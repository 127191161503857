@import "vars_and_mixins.scss";

.extra{
  @include z-index(extra);
  right: 0px;
  top: 0px;
  position: fixed;
  height: 0;
  height: 90%;
  left: 0;
  overflow: hidden;
  transform: translateY(-100%);
  transition: transform .5s;
  background-color: rgba(#118c7e,1);

  .simplebar-track.simplebar-vertical{
    border-radius: 8px;
    background-color: #006372;

    .simplebar-scrollbar:before {
      opacity: .8;
      transition: opacity 0s linear;
      background-color: $yellow;
      border-radius: 8px;
      left: 0;
      right: 0;
      margin-top: -2px;
      margin-bottom: -4px;
    }
  }

  .swiper {
    margin-top: 50px;
    .swiper-slide>img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }
  }

  &.open{
    transform: translateY(0%);
  }



  .close{
    position: absolute;
    bottom: 10px; right: 10px;
    padding: 10px;

    @include breakpoint(md) {
      right: 30px;
    }



    svg{
      width: 25px;

    }
  }

  .extraContent{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;

    @include breakpoint(md) {
      flex-direction: row;
      justify-content: center;

    }

    .barWrapper{
      padding: 40px 20px 60px 20px;
      position: relative;
      height: calc(100% - 20px);

      @include breakpoint(md) {
         padding: 40px 40px 60px 40px;
      }

      .row>*{
         padding: 0 20px 0 20px;
      }

      .video {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        width: 100%;
        border: solid 3px $green;

        img{
          max-width: 100%;
        }

        .over{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ytvideo-wrapper{
          border: solid 1px $white;
          padding: 10px;
          border-radius: 10px;
        }

        .ytvideo-responsive {
          overflow: hidden;
          padding-bottom: 56.25%;
          position: relative;
          height: 0;
        }

        &.iosono .ytvideo-responsive {
          padding-bottom: 52.8%;
        }

        .ytvideo-responsive iframe {
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          position: absolute;
        }
      }

      img.build1{
        position: absolute;
        top: -30px;
        right: 150px;
        opacity: .4;
      }

      img.build2{
        position: absolute;
        top: 200px;
        right: 50px;

        opacity: .4;
      }
      img.build3{
        position: absolute;
        top: 400px;
        right: 190px;
        opacity: .2;
      }

      .col-text {
        padding: 0 30px;
      }

      .col-data {
        padding: 0 ;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
        padding: 0 $size-s 30px;

        >* {
          display: flex;
          flex-direction: column;
          width: calc(33% - 10px);

          h4{
            @include font-format('extradatalabel');
            color: $white;
            margin: 0px 0 10px 0;
          }

          h5{
            @include font-format('extradata');
            color: $white;
            margin-top: 5px;
          }
        }
      }

      h2{
        font-size: 28px;
        text-transform: uppercase;
        font-weight: $font-strong;
        line-height: 1.4em;
        color: $white;
      }

      h3{
        font-size: 22px;
        line-height: 1.2em;
        margin-bottom: 10px;
        letter-spacing: .08em;
        font-weight: $font-normal;
        color: $white;

        text-transform: uppercase;
      }

      hr{
        color: $white;
        margin: 10px 0px 40px 0px;
        width: calc(100% - 40px);
      }
      p{
        font-size: 18px;
        line-height: 1.4em;
        font-weight: $font-light;
        color: $white;

        strong{
          font-weight: $font-strong;
        }

        &.big, .big{
          font-size: 32px;
          line-height: 1em;
        }

        &.name{
           font-family: $font-sans;
          font-weight: $font-strong;
          font-size: $font-size-xs;
          line-height: $line-height-s;

          margin-top:30px;
          margin-bottom: 10px;
         color: $white;
         text-align: left;
         @include breakpoint(md) {
           text-align: right;
         }
       }
       &.role{
          font-family: $font-sans;
          font-weight: $font-light;
          font-size: $font-size-xs;
          line-height: $line-height-s;

         color: $white;
         text-align: left;
         @include breakpoint(md) {
           text-align: right;
         }
       }
      }

      .listSvg{
        display: flex;
        flex-direction: column;
        div { border-bottom: solid 1px rgba($white, .3);
        padding-top: 8px;}
        img{
          width: 40px;
          float: left;
          margin-right: 10px;
        }
      }

      ul{
        li{
           font-size: 18px;
           line-height: 1.4em;
           font-weight: 300;
           color: #ffffff;

          strong{
            font-weight: 600;
          }
        }
      }
    }
  }

  $group_col: $green;
  $group_dark: $green_dark;
  $group_light1: #1d8c7e;
  $group_light2: #a9b62d;

  $pharma_col: #1494bb;
  $pharma_dark: #004b78;
  $pharma_light1: #1494bb;
  $pharma_light2: #1de7d3;

  $biotech_col: #A0569A;
  $biotech_dark: #642880;
  $biotech_light1: #b468fa;
  $biotech_light2: #fbe90d;

  $zach_col: #1782a3;
  $zach_dark: #004C54;
  $zach_light1: #24b0cc;
  $zach_light2: #8bbf67;

  $open_col: #83c7bc;
  $open_dark: #21428b;

  $zcube_col: #642880;
  $zcube_light1: #e77d9b;
  $zcube_light2: #97d4df;

  $ita_col: #108dc8;
  $ita_dark: #e0943d;
  $ita_grey: #6f6f6f;

  $zoe_col: #662481;
  $zoe_dark: #e51b72;

  &.group .extraContent{
    background: $group_col;
  }
  &.pharma .extraContent{
    background: $pharma_col;
  }
  &.biotech .extraContent{
    background: $biotech_col;
  }
  &.zach .extraContent{
    background: $zach_col;
  }
  &.openzone .extraContent{
    background: $open_dark;
  }
  &.zcube .extraContent{
    background: $zcube_col;
  }
  &.italiassistenza .extraContent{
    background: $ita_col;
  }
  &.zoe .extraContent{
    background: $zoe_col;
  }


}
