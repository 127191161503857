// COLORS
$black:                   #000000;
$white:                   #ffffff;
$light-grey:              #f2f4f2;
$dark-grey:               #333333;
$grey:                    #333333;


// 2023
$tronco:                  #11406b;
$foglie1:                 #0c887d;
$foglie2:                 #7dc5ca;
$foglie3:                 #0094ba;
$foglie4:                 #b4bb00;

// 2024
$green:                   #118c7e;
$green_dark:              #006372;


$yellow:                  #afba24;



$sky:                     #188c9b;
$violet:                  #5d6eae;
$greengroup:              #198b7e;
$yellowgroup:             #a9b42d;

$orangeia:                #0064ac;
$blueia:                  #ee9230;

$greenoz:                 #00ada7;
$violetoz:                #5a4591;

$green_light:             #25808e;
$green_hl:                #709919;
$violet_light:            #6878a7;
$violet_dark:             #422761;

$green_ball:              #797e38;
$green_ball2:             #03495a;
$violet_ball:             #2a4a7f;
$violet_ball2:            #3c4c67;
$violet_ball3:            #2a4a7f;


@mixin sfumatura($num) {

  @if $num == "sky"{
    background-image: linear-gradient(to bottom left, rgba($sky, 1) 40%, rgba($white, 1) 100%); //group
  }
  @if $num == "undersky"{
    background-image: linear-gradient(to left, rgba($sky, 1) -20%, rgba($white, 1) 100%); //group
  }
  @if $num == "group_land"{
    background-image: linear-gradient(to bottom, rgba($yellow, 1) 0%, rgba($green, 1) 54%); //group
  }

  /** OLD VR **/
  @if $num == "group_light"{
    background-image: linear-gradient(to right, rgba($yellow, .5) 0%, rgba($green_light, .5) 50%, rgba($violet, .5) 100%); //group
  }
  @if $num == 0 or $num == "benessere"{
    background-image: linear-gradient(to bottom, $green_hl 0%, $green_light 50%, $violet 100%); //group
  }

  @if $num == 1 or $num == "group"{
    background-image: linear-gradient(to left, $greengroup 0%, $yellowgroup 100%); //group
  }
  @if $num == 2 or $num == "pharma" {
    background-image: linear-gradient(to right, $green_dark 0%, $green_light 100%); //pharma
  }
  @if $num == 2 or $num == "pharma_light" {
    background-image: linear-gradient(to right, rgba($green_dark, .5) 0%, rgba($green_light, .5) 100%); //pharma
  }

  @if $num == 3 or $num == "zach" {
    background-image: linear-gradient(to bottom, darken($violet, 7%) 0%, $green 70%); //zach
  }
  @if $num == 3 or $num == "zach_light" {
    background-image: linear-gradient(to right, rgba($yellow, .5) 0%, rgba($green, .5) 70%); //zach
  }
  @if $num == 4 or $num == "openzone" {
    background-image: linear-gradient(to top, $greengroup 0%, $violetoz 100%); //openzone
  }
  @if $num == 4 or $num == "openzone_light" {
    background-image: linear-gradient(to right, rgba($green_dark, .5) 0%, rgba($violet_light, .5) 70%); //openzone
  }
  @if $num == 5 or $num == "zcube" {
    background-image: linear-gradient(to right, darken($yellow, 7%) 0%, $blue 70%) //zcube
  }
  @if $num == 5 or $num == "zcube_light" {
    background-image: linear-gradient(to right, rgba($yellow, .5) 0%, rgba($blue, .5) 70%) //zcube
  }
  @if $num == 6 or $num == "italiassistenza" {
    background-image: linear-gradient(to top, $orangeia 0%, $blueia 100%) //italiassistenza
  }
  @if $num == 6 or $num == "italiassistenza_light" {
    background-image: linear-gradient(to right, rgba($violet_dark, .5) 30%, rgba($blue_light, .5) 100%) //italiassistenza
  }
  @if $num == 7 or $num == "zbiotech" {
    background-image: linear-gradient(to right, darken($yellow, 7%) 0%, $violet_dark 70%) //biotech
  }
  @if $num == 7 or $num == "zbiotech_light" {
    background-image: linear-gradient(to right, rgba($yellow, .5) 0%, rgba($violet_dark, .5) 70%) //biotech
  }
  @if $num == 8 or $num == "zoe" {
    background-image: linear-gradient(to right, $blue_light 30%, $green 100%) //zoe
  }
  @if $num == 8 or $num == "zoe_light" {
    background-image: linear-gradient(to right, rgba($blue_light, .5) 30%, rgba($green, .5) 100%) //zoe
  }
}
