@mixin breakpoint-down($point) {
  @if $point == xxl {
    @media (max-width: 1399.98px) { @content ; }
  }
  @if $point == xl {
    @media (max-width: 1199.98px) { @content ; }
  }
  @if $point == lg {
    @media (max-width: 991.98px) { @content ; }
  }
  @if $point == md {
    @media (max-width: 767.98px) { @content ; }
  }
  @if $point == sm {
    @media (max-width: 575.98px) { @content ; }
  }
}

@mixin breakpoint($point) {
  @if $point == xxxl {
    @media (min-width: 1600px) { @content ; }
  }
  @if $point == xxl {
    @media (min-width: 1400px) { @content ; }
  }
  @if $point == xl {
    @media (min-width: 1200px) { @content ; }
  }
  @if $point == lg {
    @media (min-width: 992px) { @content ; }
  }
  @if $point == md {
    @media (min-width: 768px) { @content ; }
  }
  @if $point == sm {
    @media (min-width: 576px) { @content ; }
  }
}

@mixin breakpointH($point) {
  @if $point == xl {
    @media (min-height: 920px) { @content ; }
  }
  @if $point == lg {
    @media (min-height: 768px) { @content ; }
  }
  @if $point == md {
    @media (min-height: 672px) { @content ; }
  }
  @if $point == sm {
    @media (min-height: 568px) { @content ; }
  }
}
