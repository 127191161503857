@import "vars_and_mixins.scss";

$popHeadDur:  .9s; // 4.4s;
$popopDur:    .4s; // 4.2s;
$popopDur2:   .5s; // 3.8s;

$group_light1: #1d8c7e;
$group_light2: #a9b62d;

.p1g1s2 { stop-color: $group_light2; opacity: 0;}
.p1g1s1 { stop-color: $group_light1; }
.p1g2s1 { stop-color: #008c36; }
.p1g2s2 { stop-color: $white; }

$pharma_light1: #1494bb;
$pharma_light2: #1de7d3;

.p2g1s2 { stop-color: $pharma_light2; }
.p2g1s1 { stop-color: $pharma_light1; }
.p2g2s1 { stop-color: $pharma_light1; }
.p2g2s2 { stop-color: $white; }

$biotech_light1: #b468fa;
$biotech_light2: #fbe90d;

.p3g1s1 { stop-color: $biotech_light1; }
.p3g1s2 { stop-color: $biotech_light2; }
.p3g2s1 { stop-color: $biotech_light1; }
.p3g2s2 { stop-color: $white; }


$zach_light1: #24b0cc;
$zach_light2: #8bbf67;

.p4g1s1 { stop-color: $zach_light1; }
.p4g1s2 { stop-color: $zach_light2; }
.p4g2s1 { stop-color: $zach_light1; }
.p4g2s2 { stop-color: $white; }

/**/

$zcube_col: #642880;
$zcube_light1: #e77d9b;
$zcube_light2: #97d4df;

.p5g1s1 { stop-color: $zcube_light1; }
.p5g1s2 { stop-color: $zcube_light2; }
.p5g2s1 { stop-color: $zcube_col; }
.p5g2s2 { stop-color: $white; }

$open_col: #83c7bc;
$open_dark: #21428b;

.p6g1s1 { stop-color: $open_col; }
.p6g1s2 { stop-color: $open_dark; }
.p6g2s1 { stop-color: $open_dark; }
.p6g2s2 { stop-color: $white; }

$ita_col: #108dc8;
$ita_dark: #e0943d;
$ita_grey: #6f6f6f;

.p7g1s1 { stop-color: $ita_col; }
.p7g1s2 { stop-color: $ita_dark; }
.p7g2s1 { stop-color: $ita_grey; }
.p7g2s2 { stop-color: $white; }

$zoe_col: #662481;
$zoe_dark: #e51b72;

.p8g1s1 { stop-color: $zoe_col; }
.p8g1s2 { stop-color: $zoe_dark; }
.p8g2s1 { stop-color: $zoe_col; }
.p8g2s2 { stop-color: $white; }


.screen{
   width: 100%;
   top: 0px;
   height: 230vh;
   margin-top: 0px;
   transition: all .4s;
   background-image: url(../images/sfondo-mobile.png);
   background-position: bottom center;
   background-size: 100% 100%;
   position: absolute;

   &::before {
     position: fixed;
     content: url(../images/loading.svg);
     width: 100px;
     height: 100px;
     top: 50vh;
     left: calc(50% + 25px);
     transform: translate(-50%, -50%);

     @include breakpoint(md) {
        top: 50%;

     }
   }

   @include breakpoint(md) {
      background-image: url(../images/sfondo.png);
      position: fixed;
      height: calc(100vh - 50px);
   }

   .anim{
      opacity: 0;
      transition: opacity 0s;
   }

   &.show {
      &::before {
         content: "";
      }
      .anim{
         opacity: 1;
         transition: opacity .2s;
      }
   }

   .bgRep{
     width: 100%;
     height: 100%;
     position: absolute;
     background-size: 28px;
     background-repeat: repeat;
     top: 0;
     left: 0;
     opacity: .1;
   }

   .ball{
      background-color: red;
      background: linear-gradient(90deg, rgba(#59e4ff,1) 0%, rgba(#a8c518,1) 100%);
      position: absolute;
      border-radius: 50%;
      opacity: .1;

      &#ball1{
         height: 20vw;
         width: 20vw;
         top: 20%;
         left: 10%;
         animation: ballHover 7s infinite ease-in-out;
      }
      &#ball2{
         height: 12vw;
         width: 12vw;
         top: 50%;
         left: 40%;
         animation: ballHover2 6s infinite ease-in-out;
      }
      &#ball3{
         height: 16vw;
         width: 16vw;
         top: 0%;
         left: 60%;
         animation: ballHover 8s infinite ease-in-out;
      }
      &#ball4{
         height: 10vw;
         width: 10vw;
         top: 50%;
         right: 5%;
         animation: ballHover2 5s infinite ease-in-out;
      }
   }

   @keyframes ballHoverCentered {
     0%, 3%, 100% { transform: translate(-50%, 10%); }
     48%, 52% { transform: translate(-50%, 0%); }
   }

   @keyframes ballHoverCentered2 {
     0%, 3%, 100% { transform: translate(-50%, 0%); }
     48%, 52% { transform: translate(-50%, 10%); }
   }

   @keyframes ballHover {
     0%, 5%, 100% { transform: translateY(50%); }
     48%, 52% { transform: translateY(0%); }
   }

   @keyframes ballHover2 {
     0%, 5%, 100% { transform: translateY(0%); }
     48%, 52% { transform: translateY(50%); }
   }

   @keyframes scrollUpandDown {
     0%, 5%, 100% { transform: translateY(-20%); }
     48%, 52% { transform: translateY(0%); }
   }

   .griglia{
      position: absolute;
      z-index: 999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .or{
         width: 100%;
         height: 1px;
         left: 0;
         background-color: rgba(grey, .1);
         position: absolute;

         &:nth-child(1){ top: 10%;}
         &:nth-child(2){ top: 20%;}
         &:nth-child(3){ top: 30%;}
         &:nth-child(4){ top: 40%;}
         &:nth-child(5){ top: 50%;}
         &:nth-child(6){ top: 60%;}
         &:nth-child(7){ top: 70%;}
         &:nth-child(8){ top: 80%;}
         &:nth-child(9){ top: 90%;}
      }

      .ver{
         height: 100%;
         width: 1px;
         left: 0;
         background-color: rgba(grey, .1);
         position: absolute;

         &:nth-child(10){ left: 10%;}
         &:nth-child(11){ left: 20%;}
         &:nth-child(12){ left: 30%;}
         &:nth-child(13){ left: 40%;}
         &:nth-child(14){ left: 50%;}
         &:nth-child(15){ left: 60%;}
         &:nth-child(16){ left: 70%;}
         &:nth-child(17){ left: 80%;}
         &:nth-child(18){ left: 90%;}
      }
   }

   &.step_0 .plat .platSvg{
      width: 170px;

      @include breakpoint(sm) {
         width: 200px;
      }

      @include breakpoint(lg) {
         width: 220px;
      }
      @include breakpoint(xl) {
        @include breakpointH(md) {
          width: 280px;
        }
      }
   }

   .plat{
      margin-top: -8px;

      @include breakpoint(lg) {
         margin-top: -3px;
    }

     @include breakpoint(xl) {
        margin-top: 0px;
    }

      position: absolute;

      .platSvg{
         width: 150px;

         @include breakpoint(sm) {
            width: 180px;
         }

         @include breakpoint(md) {
            width: 120px;
         }


         @include breakpoint(lg) {
            width: 150px;
         }
         @include breakpoint(xl) {
            @include breakpointH(md) {
               width: 180px;
            }
         }

         transition: all .2s;
      }

      &:hover .platSvg{
         transform: translateY(-3px)
      }
   }


   img.future{
      position: absolute;
      opacity: 1;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 10%);
      transition: all .4s;
      animation: ballHoverCentered2 5s infinite ease-in-out;
      display: none;
    //   width: 160px;
    //
    //   @include breakpoint(lg) {
    //      width: 180px;
    //
    //  }
    //  @include breakpoint(xl) {
    //     @include breakpointH(md) {
    //       width: 280px;
    //   }
    // }
    width: 150px;

    @include breakpoint(sm) {
      width: 170px;
   }

    @include breakpoint(lg) {
      width: 210px;
   }
   @include breakpoint(xl) {
     @include breakpointH(md) {
        width: 250px;
    }
 }
   }

   img.sustainable{
      position: absolute;
      opacity: 1;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 10%);
      transition: all .4s;
      animation: ballHoverCentered 5s infinite ease-in-out;

      display: none;
    //   width: 170px;
    //
    //   @include breakpoint(lg) {
    //      width: 190px;
    //
    //  }
    //  @include breakpoint(xl) {
    //     @include breakpointH(md) {
    //       width: 240px;
    //   }
    // }
    width: 150px;

    @include breakpoint(sm) {
      width: 170px;
   }

    @include breakpoint(lg) {
      width: 210px;

   }
   @include breakpoint(xl) {
     @include breakpointH(md) {
        width: 250px;
    }
 }

   }

   &.step_0 img.future{
      display: block;
   }

   &.step_0 .sustainable{
      display: block;
   }

   .plat{
      .obj{
         position: absolute;
         left: 50%;
         bottom: 50%;
         transform: translate(-50%, 0%);
         opacity: 0;
         transition: all .4s;
         transition-delay: 50ms;

         &.obj1{
            width: 130px;

            @include breakpoint(sm) {
               width: 160px;
            }

            @include breakpoint(md) {
               width: 110px;
            }

            @include breakpoint(lg) {
               width: 130px;
            }
            @include breakpoint(xl) {
               @include breakpointH(md) {
                  width: 160px;
               }
            }
         }

         &.obj2{
            transform: translate(-60%, 0%);
            width: 140px;

            @include breakpoint(sm) {
               width: 170px;
            }
            @include breakpoint(md) {
               width: 110px;
            }
            @include breakpoint(lg) {
               width: 140px;
            }
            @include breakpoint(xl) {
               @include breakpointH(md) {
                  width: 170px;
               }
            }
         }

         &.obj3{
            width: 80px;

            @include breakpoint(sm) {
               width: 100px;
            }

            @include breakpoint(md) {
               width: 60px;
            }
            @include breakpoint(lg) {
               width: 80px;
            }
            @include breakpoint(xl) {
               @include breakpointH(md) {
                 width: 100px;
              }
            }
         }

         &.obj4{
            transform: translate(-70%, 0%);
            width: 110px;

            @include breakpoint(sm) {
               width: 140px;
            }

            @include breakpoint(md) {
               width: 90px;
            }

            @include breakpoint(lg) {
               width: 110px;
            }
            @include breakpoint(xl) {
               @include breakpointH(md) {
                  width: 140px;
               }
            }
         }

         &.obj5{
            transform: translate(-60%, 0%);
            width: 120px;

            @include breakpoint(sm) {
               width: 140px;
            }

            @include breakpoint(md) {
               width: 100px;
            }
            @include breakpoint(lg) {
               width: 120px;
            }
            @include breakpoint(xl) {
               @include breakpointH(md) {
                  width: 140px;
               }
            }
         }

         &.obj6{
            transform: translate(-70%, 0%);
            width: 120px;

            @include breakpoint(sm) {
               width: 140px;
            }

            @include breakpoint(md) {
               width: 100px;
            }
            @include breakpoint(lg) {
               width: 120px;
            }
            @include breakpoint(xl) {
               @include breakpointH(md) {
                  width: 140px;
               }
            }
         }

         &.obj7{
            transform: translate(-60%, 0%);
            width: 120px;

            @include breakpoint(sm) {
               width: 140px;
            }

            @include breakpoint(md) {
               width: 110px;
            }

            @include breakpoint(lg) {
               width: 120px;
            }
            @include breakpoint(xl) {
               @include breakpointH(md) {
                  width: 140px;
               }
            }
         }

         &.obj8{
            width: 100px;

            @include breakpoint(sm) {
               width: 120px;
            }
            @include breakpoint(md) {
               width: 80px;
            }
            @include breakpoint(lg) {
               width: 100px;
            }
            @include breakpoint(xl) {
               @include breakpointH(md) {
                  width: 120px;
               }
            }
         }
      }

      &.objVisible .obj{
         animation-name: popObj;
         animation-duration: 1.0s;
         animation-fill-mode: forwards;
         animation-delay: .5s;
      }

      &:hover .obj{
         transform: translate(-50%, -10px);

         &.obj2{
            transform: translate(-60%, -5%);
         }

         &.obj4{
            transform: translate(-70%, -10px);
         }
         &.obj5{
            transform: translate(-60%, -5%);
         }

         &.obj6{
            transform: translate(-70%, -10px);
         }

         &.obj7{
            transform: translate(-60%, -10px);
         }
      }
   }



   .rect{
      position: absolute;
      height: 48px;

      @include breakpoint(sm) {
         height: 58px;
      }

      @include breakpoint(md) {
         height: 34px;
      }

      @include breakpoint(lg) {
         height: 48px;
      }

      @include breakpoint(xl) {
         @include breakpointH(md) {
            height: 58px;
         }
      }

      &.rect6{
         height: 48px;

         @include breakpoint(sm) {
            height: 58px;
         }

         @include breakpoint(md) {
            height: 40px;
         }

         @include breakpoint(lg) {
            height: 58px;
         }

         @include breakpoint(xl) {
            @include breakpointH(md) {
               height: 78px;
            }
         }
      }


      //height: 1px;
      transform-origin: left center;
      // background: #a8c518 !important;
      // background: green !important;
      background: linear-gradient(90deg, rgba(#a8e4ff,1) 0%, rgba(#a8c518,1) 80%);
   }

   &.step_0 .rect{
      height: 58px !important;

      @include breakpoint(lg) {
         height: 68px !important;
      }
      @include breakpoint(xl) {
        @include breakpointH(md) {
          height: 78px;
        }
      }
   }
   .rect1{
      background: linear-gradient(-90deg, rgba(#a8e4ff,1) 0%, rgba(#a8c518,1) 80%);

      .screen.step_0 &, .screen.step_1 &{
         background: linear-gradient(90deg, rgba(#a8e4ff,1) 0%, rgba(#a8c518,1) 80%);
      }
   }


   .preheading{
     opacity: 0;
     transition: all .4s;
     text-align: left;

     display: flex;
     flex-direction: column;
     align-items: flex-start;
     top: 20px;
     left: 25px;

     position: absolute;
     animation-name: popHeadSmart;
     animation-duration: $popHeadDur;
     animation-fill-mode: forwards;
     display: none;

     @include breakpoint(lg) {
        animation-name: popHead;
        align-items: center;
        left: 50%;
        top: 50px;
        transform: translate(-53%, -20px);
        text-align: center;
     }




     h3{
       opacity: 0;
       font-weight: 300;
       color: #004c57;
       letter-spacing: .15em;
       margin-right: 40px;
       font-size: 23px;
       transition: opacity .4s;
       strong{
         font-weight: 700;
       }


       @include breakpoint(xl) {
          @include breakpointH(md) {
            font-size: 30px;
          }
       }

       @include breakpoint(xxl) {
          @include breakpointH(md) {
            font-size: 32px;
          }
       }
     }

     h5{
        opacity: 0;
        text-align: left;
        font-size: 16px;
         font-weight: 200;
         color: #006372;
         font-weight: 700;
         margin-bottom: 20px;
         display: block;

         @include breakpoint(lg) {
            display: none;
         }
     }

   }

   &.show .preheading{
     @include breakpoint(sm) {
        display: block;
     }
   }

   &.step_0 .preheading{
     h3{
       opacity: 1;
     }
     h5{
        opacity: 1;
     }
   }

   .scroll{
     position: fixed;
     right: 50px;
     bottom: 50px;
     opacity: 0;
     animation: scrollUpandDown 2s infinite ease-in-out;
     opacity: 0;

     .arrowDown{
       width: 30px;
       fill: $yellow;
       transform: rotate(90deg);
     }

     @include breakpoint(md) {
        right: auto;
        left: 50%;
        position: absolute;
        bottom: 10px;

        transform: translateX(-50%);
     }

     @include breakpoint(xl) {
       bottom: 0px;
     }
   }
   &.step_0.show .scroll{
     opacity: 1;
   }

   .heading{
     opacity: 0;
     transition: all .4s;
     text-align: center;
     padding-top: 20px;
     transform: translateY(10px);
     display: flex;
     flex-direction: column;
     align-items: center;

     .susfutSvg{
       transition: all .4s;
       width: 220px;

       @include breakpoint(sm) {
          width: 260px;
       }

       @include breakpoint(lg) {
          width: 300px;
       }
       @include breakpoint(xl) {
          @include breakpointH(md) {
             width: 380px;
          }
       }


       .f {
         opacity: .6;
       }

       .g {
         fill: #c2c800;
       }

       .g, .h, .i {
         stroke-width: 0px;
       }

       .h {
         fill: url(#e);
       }

       .i {
         fill: url(#d);
       }
     }

     @include breakpoint(lg) {
        padding-top: 70px;
     }

     h2{
       opacity: 0;
       transition: all .8s;
       margin-top: 10px;
       font-weight: 200;
       letter-spacing: .15em;
       font-size: 18px;
       color: #004c57;
       position: relative;
       display: flex;
       margin-left: -10px;
       display: flex;
       transition-delay: 2s;

       @include breakpoint(sm) {
          font-size: 22px;
       }

       @include breakpoint(lg) {
          margin-top: 10px;
          font-size: 24px;
       }
       @include breakpoint(xl) {
          @include breakpointH(md) {
             margin-top: 20px;
            font-size: 28px;
          }
       }

       span{
         z-index: 1;
       }

       &:before{
         content: "";
         z-index: 0;
         position: absolute;
         border-radius: 4px;
         width: 110%;
         bottom: 3px;
         left: 50%;
         transform: translateX(-50%);
         background: linear-gradient(90deg, #59e4ff, #a8c518 100%);
         height: 8px;
       }
     }

     h5{
        opacity: 0;
        text-align: left;
        font-size: 14px;
         font-weight: 200;
         color: #006372;
         font-weight: 700;
         margin-bottom: 20px;
         display: block;

         @include breakpoint(lg) {
            display: none;
         }
     }
   }

   @include breakpoint-down(md) {
      .heading{
         opacity: 1;
         transform: translateY(0px);
         h2{
           opacity: 1;
         }
         h5{
           opacity: 1;
         }
      }

      &.step_0 .heading, &.step_1 .heading{
         opacity: 0;
         h2{
           opacity: 0;
         }
      }
   }

   @include breakpoint-down(sm) {
      &.show .heading{
         opacity: 1 !important;
         transform: translateY(0px);
         h2{
           opacity: 1 !important;
         }
      }
   }

   &.step_13 .heading{
     opacity: 1;
     transform: translateY(0px);
     h2{
       opacity: 1;
     }
   }

   .rect1 p{
      display: none;
      position: absolute;
      top: 50%;
      left: calc(50% - 20px);
      color: white;
      font-weight: 500;
      font-size: 12px;
      transform: translate(-14%, -50%) scaleX(150%) skewX(30deg);

      @include breakpoint(sm) {
         font-size: 16px;
         transform: translate(-26%, -50%) scaleX(150%) skewX(30deg);
      }

      @include breakpoint(lg) {
         font-size: 20px;
      }

      @include breakpoint(xl) {
         font-size: 23px;
         transform: translate(-37%, -50%) scaleX(150%) skewX(30deg);
      }

      @include breakpoint(xxl) {
         font-size: 28px;
      }

      @include breakpoint(xxxl) {
         font-size: 36px;
      }
   }
   &.step_0 .rect1 p{
      display: none;
      @include breakpoint(sm) {
         display: block;
      }
   }

   .plat{
      opacity: 1;

      h2{
         position: absolute;
         text-align: left;
         opacity: 0;
         font-weight: 400;
         line-height: 24px;
         font-size: 20px;
         transition: all .4s;
         white-space: nowrap;
         text-transform: uppercase;

         strong{
            font-weight: 700;
         }

         @include breakpoint(xl) {
           @include breakpointH(md) {
             font-size: 24px;
           }
         }
      }

      &.plat1, &.plat2, &.plat7, &.plat8{
         &:hover h2{
            margin-top: -4px;
         }
      }

      &.plat3, &.plat4, &.plat6, &.plat5{
         &:hover h2{
            margin-bottom: 4px;
         }
      }

      &.plat1 h2{
         color: #118c7e;
         left: 0%;
         top: 0%;
         transform: translate(80%, -140%);

         @include breakpoint(lg) {
            transform: translate(80%, -120%);
         }
      }

      &.plat2 h2{
         color: #1494bb;
         left: 50%;
         top: 0%;
         transform: translate(0%, -190%);

         @include breakpoint(lg) {
            transform: translate(0%, -160%);
         }

         @include breakpoint(xl) {
           @include breakpointH(md) {
             transform: translate(0%, -150%);
           }
         }
      }

      &.plat3 h2{
         color: #a0569a;
         left: 50%;
         bottom: 0%;
         transform: translate(-65%, 150%);

         @include breakpoint(xl) {
           @include breakpointH(md) {
             transform: translate(-65%, 165%);
           }
         }

      }

      &.plat4 h2{
         color: darken(#1782a3, 5%);
         left: 55%;
         bottom: 10%;
         transform: translate(-55%, 210%);


         @include breakpoint(xl) {
           @include breakpointH(md) {
             transform: translate(-55%, 230%);
           }
         }
      }

      &.plat5 h2{
         color: #036a99;
         left: 0%;
         bottom: 0%;
         transform: translate(15%, 220%);

         @include breakpoint(xl) {
           @include breakpointH(md) {
             transform: translate(15%, 240%);
           }
         }
      }

      &.plat6 h2{
         color: #642880;
         left: 50%;
         bottom: 0%;
         transform: translate(-35%, 240%);

         @include breakpoint(md) {
            transform: translate(55%, -130%);
         }

         @include breakpoint(xl) {
           @include breakpointH(md) {
             transform: translate(50%, -180%);
           }
         }
      }

      &.plat7 h2{
         color: #e48100;
         left: 50%;
         top: 0%;
         transform: translate(-100%, -290%);

         span{
            color: darken(#108dc8, 4%);
         }

         @include breakpoint(md) {
            transform: translate(10%, -330%);
         }

         @include breakpoint(lg) {
            transform: translate(10%, -260%);
         }

         @include breakpoint(xl) {
           @include breakpointH(md) {
             transform: translate(10%, -255%);
           }
         }
      }

      &.plat8 h2{
         color: #a0569a;
         left: 10%;
         top: 0%;
         transform: translate(-5%, -250%);

         @include breakpoint(md) {
            transform: translate(-5%, -280%);
         }

         @include breakpoint(lg) {
            transform: translate(-5%, -230%);
         }

         @include breakpoint(xl) {
           @include breakpointH(md) {
             transform: translate(-5%, -230%);
           }
         }
      }

      &.objVisible h2{
         animation-name: popText;
         animation-duration: 0.8s;
         animation-fill-mode: forwards;
         animation-delay: 0.8s;
      }
   }

   .plat1, .plat3, .plat5, .plat7{
      transform: translate(-50%, -50%);
   }
   .plat2, .plat4, .plat6, .plat8{
      transform: translate(-50%, -50%);
   }
   .plat1{
     .ell2{ fill: url(#p1g1); }
     .rectang, .ell1{ fill: url(#p1g2); }
   }
   .plat2{
      .ell2{ fill: url(#p2g1); }
      .rectang, .ell1{ fill: url(#p2g2); }
   }
   .plat3{
      .ell2{ fill: url(#p3g1); }
      .rectang, .ell1{ fill: url(#p3g2); }
   }
   .plat4{
      .ell2{ fill: url(#p4g1); }
      .rectang, .ell1{ fill: url(#p4g2); }
   }
   .plat5{
      .ell2{ fill: url(#p5g1); }
      .rectang, .ell1{ fill: url(#p5g2); }
   }
   .plat6{
      .ell2{ fill: url(#p6g1); }
      .rectang, .ell1{ fill: url(#p6g2); }
   }
   .plat7{
      .ell2{ fill: url(#p7g1); }
      .rectang, .ell1{ fill: url(#p7g2); }
   }
   .plat8{
      .ell2{ fill: url(#p8g1); }
      .rectang, .ell1{ fill: url(#p8g2); }
   }
}






@keyframes opop {
   0% {opacity: 0;}
   30% {opacity: 0;}
   100% {opacity: 1;}
}
@keyframes popop {
   0% {opacity: 0; bottom: 0%;}
   100% {opacity: 1; bottom: 60%;}
}
@keyframes  popObj{
   0% {opacity: 0; bottom: 0%;}
   100% {opacity: 1; bottom: 40%;}
}
@keyframes  popHead{
   0% {opacity: 0; top: 10%;}
   100% {opacity: 1; top: 14%;}
}
@keyframes  popHeadSmart{
   0% {opacity: 0; }
   100% {opacity: 1; }
}
@keyframes  popText{
   0% {opacity: 0; bottom: 40%; right: 40%;}
   100% {opacity: 1; bottom: 40%; right: 0%;}
}
