
*, ::after, ::before {
  box-sizing: border-box;
}

button{
   background: transparent;
   border: 0;

   &:focus {
     outline: none;
     box-shadow: none;
   }
}
// @include breakpoint-down(sm){
//    .forder1{
//       order: 1;
//    }
//    .forder2{
//       order: 2;
//    }
// }

.row{
margin: 0;
}

.mw100{
   max-width: 100%;
}

.aic{
   align-items: center;
}

.jcc{
   justify-content: center;
}

.dis-flex{
   display: flex;
}

.p30_0{
   padding: 30px 0 !important;
}

.p0_30{
 padding: 0 30px !important;
}

.pl_15{
 padding-left: 15px !important;
}

.pr-5{
   padding-right: 5px !important;
}

.pl-5{
   padding-left: 5px !important;
}

.pr_15{
 padding-right: 15px !important;
}

hr.green{
background-color: $yellow;
}

hr.yellow{
background-color: $yellow;
margin: 0;
opacity: .5;
}

hr.white{
background-color: $white;
opacity: 1;
}


.mt-6{
   margin-top: 60px;
}

.ml-2{
margin-left: 20px;
}

@include breakpoint(sm){
   .mb-1-sm{
     padding-bottom: 10px !important;
   }
   .mt-1-sm{
     padding-top: 10px !important;
   }
   .mb-2-sm{
     padding-bottom: 20px !important;
   }
   .mt-2-sm{
     padding-top: 20px !important;
   }
   .mb-4-sm{
     padding-bottom: 40px !important;
   }
   .mt-4-sm{
     padding-top: 40px !important;
   }
   .mb-6-sm{
     padding-bottom: 60px !important;
   }
   .mt-6-sm{
     padding-top: 60px !important;
   }
   .pr-sm-5{
      padding-right: 5px !important;
   }

   .pl-sm-5{
      padding-left: 5px !important;
   }
}

@include breakpoint(md){
   .mt-0-md{
      margin-top: 0 !important;
   }


   .pl-md-15{
    padding-left: 15px !important;
   }

   .pr-md-15{
    padding-right: 15px !important;
   }
}

.row > *{
padding: 0;
}

.col-2-9 {
flex: 0 0 auto;
width: calc(100% / 9 * 2);
}

.col-lg-2-9 {
@include breakpoint(lg) {
  flex: 0 0 auto;
  width: calc(100% / 9 * 2);
}
}

.col-3-9 {
flex: 0 0 auto;
width: calc(100% / 9 * 3);
}

.col-4-9 {
flex: 0 0 auto;
width: calc(100% / 9 * 4);
}

.col-lg-4-9{
@include breakpoint(lg) {
  flex: 0 0 auto;
  width: calc(100% / 9 * 4);
}
}

.col-7-9 {
flex: 0 0 auto;
width: calc(100% / 9 * 7);
}

a, a:focus, a:hover {

}

ul li {

}

table{
margin-bottom: $size_s;
}

h1, h2, h3, h3, h4, h5, h6{
margin: 0;
}


.wsnw{
white-space: nowrap
}

.topright{
position: absolute;
top: 15px;
right: 15px;
max-width: 50%;

@include breakpoint(lg) {
  top: 30px;
  right: 30px;
}
}

.bottomleft{
position: absolute;
bottom: 15px;
left: 15px;
@include breakpoint(lg) {
  bottom: 30px;
  left: 30px;
}
}

.bottomright{
position: absolute;
bottom: 15px;
right: 15px;

@include breakpoint(lg) {
  bottom: 30px;
  right: 30px;
}
}

.plusContainer{
@include z-index(plusBtn);
}



.cta{
  @include z-index(cta);

  text-align: left;
  line-height: 1.1em;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  .arrow{
    transition: all .4s;
  }

  .arrow{
    border-radius: 10px;
    background-color: $yellow;
    padding: 5px 10px 5px;
    svg{
      width: 40px;
      fill: white;
    }
  }

  span{
    font-size: 18px;
    line-height: 1.2em;
    margin-top: 3px;
    display: block;
    text-transform: uppercase;
    color: #006372;
  }

  &:hover{
    .arrow{
      transform: translate(10px, 0px);
    }
  }

  &.ctaBack{
    .arrow{
      svg{
        transform: rotate(180deg);
      }
    }
    span{
      margin-left: 10px;
    }

    &:hover{
      .arrow{
        transform: translate(-10px, 0px);
      }
    }
  }

  &.ctaLetter{
    flex-direction: column;
    align-items: flex-start;
    .letterSvg{
      width: 90px;
    }
    .eyeSvg{
      width: 50px;
    }
    span{
      margin: 10px 0;
    }
  }


  &.ctaEye{
    flex-direction: row;
    align-items: flex-start;
    text-decoration: none;

    .eyeSvg{
      flex: 0 0 50px;
    }

    .webSvg{
      margin-left: 15px;
      flex: 0 0 35px;
    }

    .content{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 15px;

      .letterSvg{
        width: 90px;
      }

      span{
        font-weight: $font-strong;
        &.wsnw{
          white-space: nowrap;
        }
        &.light{
           white-space: nowrap;
           font-weight: $font-light;
        }
      }
      .arrow{
        margin: 10px 0 0;
      }
    }
  }

  &.ctaTwwa{
    position: absolute;
    bottom: 30px;
    right: 100px;
  }

  &.ctaBackfs{
    position: absolute;
    top: 50px;
    left: 50px;
    width: 60px;
    height: 60px;

    svg{
      width: 60px;
    }
  }

  .homeSvg{
    width: 70px;
  }

  .ctaSvg{
    fill:$white;
    width: 25px;

    .circlePath{ stroke:$white; fill:none; }
  }

  &.plus,&.play,&.link{
    background-color: $yellow;
    box-shadow: inset 3px 3px 3px #333333;
  }

  &.sustainability{
    background-color: $green_hl;
    box-shadow: inset 3px 3px 3px #333333;
  }

  &.plus{
    .ctaSvg{
      margin-left: 3px;
      margin-top: 3px;
    }
  }

  &.play{
    .ctaSvg{
      margin-left: 7px;
      margin-top: 3px;
    }
  }

  &.link{
    .ctaSvg{
      margin-right: 2px;
      margin-top: 2px;
    }
  }

  &.sustainability{
    .ctaSvg{
      width: 35px;
      margin-left: 3px;
      margin-top: 4px;

      .cls-1 {
        fill: #f9f9f9;
      }

      .cls-2 {
        fill: #f8f8f8;
      }
    }
  }

  .circlePath{ stroke:#c3c900; fill:none; }

  &.play2{
    border: solid 2px #c3c900;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    align-self: center;
    background: rgba(#118c7e, .5);

    .ctaSvg{
      fill: #c3c900;
      margin-left: 12px;
      margin-top: 3px;
    }
  }

  &.play3{
    margin-top: 120px;
    border: solid 2px $white;
    .ctaSvg{
      margin-left: 7px;
      margin-top: 3px;
    }
  }

  &:hover{
    &.plus,&.play,&.link{
      background-color: darken($yellow, 5%);
      box-shadow: inset 4px 4px 4px #333333;
    }

    &.sustainability{
      background-color: darken($green_hl, 5%);
      box-shadow: inset 4px 4px 4px #333333;
    }

    &.play2{
      background-color: rgba($white, .3);
    }
  }

  &.grey{
    .ctaSvg{
      fill:$grey;

      .circlePath{ stroke:$grey; }
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.hlb{
background-color: rgba($green_hl, .4);
}

.colgreen{
color: $yellow;
}

.tac{
text-align: center !important;
}
.tac_wm{
margin: 0 auto;
}
.tal{
text-align: left;
}
.tar{
text-align: right;
}

.tar-md{
   @include breakpoint(md) {
      text-align: right;
   }
}



.asfs{
align-self: flex-start;
}
.asc{
align-self: center;
}
.aic{
align-items: center;
}




.internalPage {

  .swiper-slide{
    display: flex;
    flex-direction: row;
    align-items: center;

    &:first-child{
      width: 100%;
    }

  }
  .swiper-button-prev,
  .swiper-button-next{
    color: $yellow;
    transform: scale(.8, 1.4);

    &.swiper-button-disabled {
      opacity: 0.0;
    }
  }


  .swiper-scrollbar-drag {
    background: rgba($yellow, 0.5);
  }

  .swiper-pagination-bullet-active{
    background: $yellow;
  }

  .swiper-horizontal > .swiper-scrollbar {
      bottom: auto;
      top: 1px;
      left: 0;
      width: 100%;
  }

  .swiper-pagination-clickable .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
  }

}
