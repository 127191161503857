@import "vars_and_mixins.scss";

@keyframes ballHover3 {
  0%, 5%, 100% { transform: translateY(0%); }
  48%, 52% { transform: translateY(10%); }
}

$group_col: $green;
$group_dark: $green_dark;
$group_light1: #1d8c7e;
$group_light2: #a9b62d;

$pharma_col: #1494bb;
$pharma_dark: #004b78;
$pharma_light1: #1494bb;
$pharma_light2: #1de7d3;

$biotech_col: #A0569A;
$biotech_dark: #642880;
$biotech_light1: #b468fa;
$biotech_light2: #fbe90d;

$zach_col: #1782a3;
$zach_dark: #004C54;
$zach_light1: #24b0cc;
$zach_light2: #8bbf67;

$open_col: #83c7bc;
$open_dark: #21428b;

$zcube_col: #642880;
$zcube_light1: #e77d9b;
$zcube_light2: #97d4df;

$ita_col: #108dc8;
$ita_dark: #e0943d;
$ita_grey: #6f6f6f;

$zoe_col: #662481;
$zoe_dark: #e51b72;

.internalPage{
  display: flex;
  overflow: hidden;
  margin-top: 0px;
  position: relative;
  flex-direction: column;
  padding-bottom: 220px;

  @include breakpoint(xl) {
    padding-bottom: 180px;
  }

  .ball{
    background: linear-gradient(90deg, rgba(#59e4ff,1) 0%, rgba(#a8c518,1) 100%);
    position: absolute;
    border-radius: 50%;
    opacity: .2;


    &#ball1{
      height: 300px;
      width: 300px;
      top: 20%;
      left: 10%;
      animation: ballHover 7s infinite ease-in-out;
    }

    &#ball2{
      height: 450px;
      width: 450px;
      top: 30%;
      left: 0%;
      animation: ballHover2 6s infinite ease-in-out;
    }

    &#ball3{
      height: 16vw;
      width: 16vw;
      top: 0%;
      left: 60%;
      animation: ballHover 8s infinite ease-in-out;
    }
    &#ball4{
      height: 10vw;
      width: 10vw;
      top: 50%;
      left: 5%;
      animation: ballHover2 5s infinite ease-in-out;
    }
    &#ball5{
      height: 20vw;
      width: 20vw;
      top: 65%;
      right: 10%;
      animation: ballHover 7s infinite ease-in-out;
    }

    &#ball6{
      height: 12vw;
      width: 12vw;
      top: 80%;
      left: 5%;
      animation: ballHover2 6s infinite ease-in-out;
    }

    &#ball7{
      height: 16vw;
      width: 16vw;
      top: 90%;
      left: 70%;
      animation: ballHover 8s infinite ease-in-out;
    }
  }
  .graph_e_nums{
    position: relative;
    display: flex;
    justify-content: center;

    .num{
      width: 150px;
      height: 150px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: calc(50%);
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p{
        text-align: center;
        font-size: 15px;
        font-weight: 700;
      }
      span{
        @include font-format('smallNumbers');
        color: $white;
      }
    }
  }

  .circle{
    padding: 50px;
    border-radius: 50%;
    @include sfumatura('zach');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;

    h4{
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 42px;
      text-transform: none;
      .small{
        font-weight: 400;
        font-size: 18px;
      }
    }
    h5{
      text-align: center;
      color: $white;
    }
    h6{
      text-align: center;
      color: $white;
    }
  }
  .quoteblock{
    border: solid 3px #c3c900;
    border-radius: 25px;
    background-color: rgba(#ffffff, .2);
    padding: 30px;

    p.quote{
      position: relative;
      margin-bottom: 0px;
      font-size: $font-size-s;
      line-height: $line-height-m;
      font-weight: $font-normal;

      @include breakpoint(xxl) {
        font-size: $font-size-m;
      }
    }

    p.quoteAuthor{
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 24px;
      font-weight: $font-strong;
      text-transform: uppercase;
      text-align: left;
      text-transform: uppercase;
    }

    p.quoteRole{
      margin-bottom: 0;
      font-size: 18px;
      font-weight: $font-light;
    }
  }
  table{
    color: $white;

    h4{
      text-transform: uppercase;
    }

    tr{
      border-bottom: solid 1px rgba(33, 37, 41, .2);

      &:last-child{
        border: none;
      }
    }

    &.pharma {

      width: 100%;

      tr:nth-child(odd){
        border-bottom: none;

        td{
          line-height: 16px;
          padding-top: 8px;
        }
      }
    }

    th{
      @include font-format('th');
      text-align: center;
    }

    td{
      @include font-format('td');

      &.small{
        @include font-format('tdsmall');
      }

      &.tar{
        padding-right: 5px;
      }
      &.tal{
        padding-left: 5px;
      }

      h3{
        display: inline;
      }

      .bigNum{
        font-size: 72px;
        line-height: 72px;
        font-weight: 600;
        color: $yellow;
      }
      .medNum{
        font-size: 56px;
        line-height: 58px;
        font-weight: 600;
        color: $yellow;
      }
      .bigNumlight{
        font-size: 56px;
        line-height: 56px;
        color: $yellow;
      }

      span.bar:not(.bigNum):not(.bigNumlight){
        display: block;
        height: 20px;
        width: 5px;
        background-color: $yellow;
        border-radius: 5px;

        &.p50{ width: 95%; background-color: #bcc7dd;}
        &.p16{ width: 32%; background-color: #c37995;}
        &.p10{ width: 20%; background-color: #7ecacf;}
        &.p8{ width: 16%; background-color: #8db7af;}
        &.p7a{ width: 14%; background-color: #3aaa35;}
        &.p3{ width: 6%; background-color: #c3c900;}
        &.p7b{ width: 14%; background-color: #006674;}
      }
    }
  }

  .tableInfo{
    @include font-format('tableInfo');
  }
  .video {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    border: solid 3px $green;

    img{
      max-width: 100%;
    }

    .over{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ytvideo-wrapper{
      border: solid 1px $white;
      padding: 10px;
      border-radius: 10px;
    }

    .ytvideo-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
    }

    &.iosono .ytvideo-responsive {
      padding-bottom: 52.8%;
    }

    .ytvideo-responsive iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  .row{
    display: flex;
    flex-direction: row;
    z-index: 2;
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;


    @include breakpoint(xxl) {
      padding: 0 0px;
    }

  }

  h1{
    font-family: $font-sans;
    font-size: 38px;
    line-height: $line-height-m;
    font-weight: $font-light;

    text-transform: uppercase;
    text-align: left;
    margin-bottom: 60px;

    @include breakpoint(md) {
      font-size: 54px;
    }

    @include breakpoint(lg) {
      font-size: 62px;
    }

    strong{
      font-weight: $font-strong;
    }

    .green{
      color: $yellow;
    }
  }
  h2{
    text-align: center;
    font-family: $font-sans;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
    font-weight: $font-strong;

    @include breakpoint(md) {
      font-size: 36px;
    }

    @include breakpoint(lg) {
      font-size: 46px;
    }

    .green{
      color: $yellow;
    }
  }

  h3{
    text-align: center;
    font-family: $font-sans;
    font-size: 24px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 20px;
    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: 28px;
    }

    @include breakpoint(lg) {
      font-size: 34px;
    }

    .green{
      color: $yellow;
    }
  }

  h4{
    text-align: center;
    font-family: $font-sans;
    font-size: 28px;
    font-weight: $font-normal;
    text-align: left;
    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: 32px;
    }

    @include breakpoint(xl) {
      font-size: 36px;
    }

    span{
      font-weight: $font-strong;
    }
  }
  h5{
    text-align: left;
    font-family: $font-sans;
    font-size: 18px;

    strong{
      font-weight: $font-strong;
      font-size: 30px;
    }

    @include breakpoint(md) {
      font-size: 20px;

      strong{
        font-weight: $font-strong;
        font-size: 42px;
      }
    }

    @include breakpoint(xl) {
      font-size: 22px;

      strong{
        font-weight: $font-strong;
        font-size: 52px;
      }
    }
  }
  h6{
    text-align: left;
    font-family: $font-sans;
    font-size: 16px;
    font-weight: $font-strong;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 10px;
    @include font-format('colParagraph');
    color: $white;

    &.fotoCredits{
      align-self: flex-start;
    }

    &.small{
      @include font-format('colParagraphSmall');
    }

    &.big{
      font-size: $font-size-l;
    }

    .green{
      color: $yellow;
    }

    strong{
      font-weight: $font-strong;

      &.green{
        color: $yellow;
      }
    }
  }

  .icon_num{
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;

     div{
        display: flex;
        margin-left: 10px;
        flex-direction: column;
        align-items: flex-start;
     }
  }

  .icon_num2{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;

     div{
        height: 120px;
        display: flex;
        flex-direction: center;
        align-items: flex-end;
        margin-bottom: 5px;
     }
  }

  .icon_num3{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: flex-start;
     position: relative;
     margin-bottom: 20px;

     &:before{
        content: "";
        width: 100px;
        height: 100px;
        background-color: rgba(white, .1);
        position: absolute;
        top: -25px;
        left: -25px;
        border-radius: 50%;
        z-index: -1;
     }
     h5{
        strong{
           font-size: 26px;
        }

        @include breakpoint(md) {

          strong{
             font-size: 42px;
          }

        }
     }


     h6{
        font-weight: 400;
        strong{
           font-weight: 700;
        }
     }

     svg{
        width: 80px;
        position: absolute;
        top: -20px;
        right: 60px;
        display: none;

        @include breakpoint(md) {
           display: block;
       }
     }
  }

  img{
    height: auto;
    max-width: 100%;

    &.imgWorld{
      max-width: 350px;


      @include breakpoint(sm) {
         margin-left: 60px;
      }

      @include breakpoint(md) {
         margin-left: 0;
      }
    }
    &.imgSchool{
      max-width: 100%;

      @include breakpoint(sm) {
         max-width: 400px;
      }

      @include breakpoint(md) {
         max-width: 100%;
      }
    }

    &.twwa{
      max-width: 100%;

      @include breakpoint(sm) {
         max-width: 440px;
      }

      @include breakpoint(md) {
         max-width: 100%;
      }
     }

     &.totDip{
        width: 350px;
        margin-bottom: 40px;
        max-width: 100%;

       @include breakpoint(md) {
          width: auto;
          margin-bottom: 0px;
       }


     }
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 20440;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  .bgRep{
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 28px;
    background-repeat: repeat;
    top: 0;
    left: 0;
    opacity: .1;
  }

  .tubeSvg{
    top: 160px;
    position: absolute;


    @include breakpoint(sm) {
     top: 260px;
    }
    opacity: .3;
    left: 40%;
    width: 100%;
    transform: translateX(-50%);

    .path {
      stroke-dasharray: 20440;
      stroke-dashoffset: 20440;
      animation: dash 20s linear forwards; //forwards
    }
  }

  .plat{
     position: relative;
     padding-right: 20px;

     .platSvg{
       width: 400px;
       max-width: 90%;
       transition: all .2s;

       .p1g1s1 { stop-color: $group_light2; }
       .p1g1s2 { stop-color: $group_light1; }
       .p1g2s1 { stop-color: #008c36; }
       .p1g2s2 { stop-color: $white; }

       .p2g1s1 { stop-color: $pharma_light1; }
       .p2g1s2 { stop-color: $pharma_light2; }
       .p2g2s1 { stop-color: $pharma_light1; }
       .p2g2s2 { stop-color: $white; }

       .p3g1s1 { stop-color: $biotech_light1; }
       .p3g1s2 { stop-color: $biotech_light2; }
       .p3g2s1 { stop-color: $biotech_light1; }
       .p3g2s2 { stop-color: $white; }

       .p4g1s1 { stop-color: $zach_light1; }
       .p4g1s2 { stop-color: $zach_light2; }
       .p4g2s1 { stop-color: $zach_light1; }
       .p4g2s2 { stop-color: $white; }

       /**/
       .p6g1s1 { stop-color: $open_col; }
       .p6g1s2 { stop-color: $open_dark; }
       .p6g2s1 { stop-color: $open_dark; }
       .p6g2s2 { stop-color: $white; }

       .p5g1s1 { stop-color: $zcube_light1; }
       .p5g1s2 { stop-color: $zcube_light2; }
       .p5g2s1 { stop-color: $zcube_col; }
       .p5g2s2 { stop-color: $white; }

       .p7g1s1 { stop-color: $ita_col; }
       .p7g1s2 { stop-color: $ita_dark; }
       .p7g2s1 { stop-color: $ita_col; }
       .p7g2s2 { stop-color: $white; }

       .p8g1s1 { stop-color: $zoe_col; }
       .p8g1s2 { stop-color: $zoe_dark; }
       .p8g2s1 { stop-color: $zoe_col; }
       .p8g2s2 { stop-color: $white; }

     }
     .obj{
        position: absolute;
     }
  }

  .svgIcon{
     position: absolute;
  }

  .pagerow{
      position: relative;
      padding: 30px 0;

      svg{
         &.ebitdaSvg{
            width: 60px;
            height: 100px;

            @include breakpoint(lg) {
              width: 80px;
              height: 120px;
           }
           .uuid-45aaab75-6b36-4406-846d-203513a36a13 {
             fill: #d8edf3;
           }
         }

         &.fatturatoSvg{
           width: 70px;
           height: 100px;

           @include breakpoint(lg) {
             width: 90px;
             height: 120px;
           }

           .uuid-fc1ae8b9-e3de-46df-9ba1-cb645907fe55 {
             fill: #d8edf3;
           }
         }

         &.utileSvg{
            width: 60px;
            height: 100px;

            @include breakpoint(lg) {
              width: 80px;
              height: 120px;
           }
           .uuid-dc26f7b2-fa3c-421a-be31-07926bbbed6b {
             fill: #d8edf3;
           }
         }


         &.dnaSvg{ position: absolute; right: 0%; bottom: 20%; width: 280px; }
         &.dnawSvg{ position: absolute; right: 0%; bottom: 20%; width: 200px; }
         &.pinsSvg{ position: absolute; left: 15%; top: 25%; width: 140px; }
         &.docSvg{ position: absolute; right: 15%; top: 50%; width: 120px; }
         &.lenteSvg{ position: absolute; right: 25%; bottom: 30%; width: 160px; }
         &.cuoSvg{ position: absolute; right: 10%; bottom: 30%; width: 200px; }
         &.nuvSvg{ position: absolute; left: 15%; bottom: 20%; width: 180px; }
         &.proSvg{ position: absolute; left: 10%; top: 50%; width: 130px; }
      }

      &.pagerow_head{
        text-align: center;
        padding-top: 80px;
        z-index: 9;

        @include breakpoint(md) {
          padding-top: 120px;
        }

        .ctaBack{
          position: fixed;
          left: 10px;
          top: 10px;



         @include breakpoint(md) {
             position: relative;
             left: auto;
             top: auto;
         }
        }

        .ball{
          height: 400px;
          width: 400px;
          top: -150px;
          left: -100px;
          z-index: -1;
          animation: ballHover3 7s infinite ease-in-out;
        }

        .title{
           text-align: left;
           margin-top: 20px;

           .logo-wrapper{
             position: relative;
             height: calc(50vw - 20px);
             width: 100%;
             border-radius: 50%;
             margin-bottom: 20px;


             @include breakpoint(md) {
                height: 300px;
                width: 300px;
             }


             .svgLogo{
               fill: $white;
               position: absolute;
               top: calc(50%);
               left: 50%;
               transform: translate(-50%, -50%);
             }
          }
        }

        h1{
          margin: 30px 0 50px 0;
        }

        .dnaSvg{
          position: absolute;
          width: 280px;
          right: 45%;
          top: 0px;
        }
      }

      &.pagerow_lettera{
        .col-lettertext{
          margin-bottom: 20px;

          @include breakpoint(sm) {
             padding: 0 30px 0 0;
             margin-bottom: 0;
          }
        }
      }

      &.pagerow_centered{
        text-align: center;
      }


      &.group_persone{

        .row{
          align-items: center;
        }

        .col-persone1{
          text-align: right;
          padding: 0 30px;
          .persone1Svg{
            margin-top: -60px;
            width: 320px;
          }
        }

        .col-persone2{
          text-align: right;
          .persone2Svg{
            width: 420px;
          }
        }

        .col-persone3{
          text-align: left;
          .persone3Svg{
            margin-top: 70px;
            width: 380px;
          }
        }

      }

      &.group_thewayweare{
        padding: 60px 0 30px;

        .col-twwabutton{
          text-align: right;

          button{
            display: inline-flex;
          }
        }

        .twwaSvg{
          width: 700px;
          max-width: 90%;
        }
      }

      .col-benvPeopleContent{
       display: flex;
       flex-direction: column;

       @include breakpoint(md) {
          flex-direction: row;
       }

       &.fdc{
          flex-direction: column;
       }


       .bgrounded{
         padding: 25px;
         font-size: 20px;
         border-radius: 15px;
         max-width: 100%;
         font-weight: $font-light;

         &.bgralone{
            margin-right: 0px;
         }
         strong{
           font-weight: $font-regular;
         }
       }

       div.buttons{


          flex: 0 0 250px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .cta{
            margin-left: 0px;
            align-self: flex-start;
            flex: 0 0 auto;
            padding: 0;
            margin-bottom: 20px;
          }

          @include breakpoint(md) {
             margin-left: 30px;
             align-self: center;
          }
       }

       .cta{
          margin-left: 0px;
          align-self: flex-start;

          margin-top: 10px;
          text-decoration: none;

          @include breakpoint(md) {
             flex: 0 0 250px;
             margin-top: 0px;
             margin-left: 30px;
             align-self: center;
          }
       }

       &.fdc{
          .cta{
             margin-left: 0px;
             margin-top: 10px;
             align-self: flex-start;
             flex: 0 0 auto;
          }
       }

     }

      .iconabs{
        position: absolute;
        width: 130px;

        &.icon1{
           top: 120px;
           right: 20%;
        }

        &.icon2{
           top: 70px;
           left: 40%;
        }

        &.icon3{
           bottom: 0px;
           right: 70%;
        }

        &.icon4{
           bottom: 100px;
           left: 60%;
        }
      }
    }

  &.groupPage{
     background-image: linear-gradient(-150deg, #ffffff 0%, #D6DB8D 25%, #77BEB5 50%, #D6DB8D 75%, #FFFFFF 100%);

     h1, h2, h3, h4, p.quote, p.quoteRole, p.quoteAuthor{ color: $group_dark; }
     h5, h6 { color: $group_dark; }

     .dnaSvg, .dnawSvg, .pinsSvg, .docSvg,
     .lenteSvg, .cuoSvg, .nuvSvg, .proSvg{ fill:url(#iconlg1);  }
     .ball{ background: linear-gradient(90deg, rgba(#59e4ff,1) 0%, rgba(#a8c518,1) 100%); }

     .tubeSvg{ .path {stroke:url(#tubeGroup)} }

     .platSvg{
         margin-top: 200px;
        .ell2{ fill: url(#p1g1); }
        .rectang, .ell1{ fill: url(#p1g2); }
        max-width: 90%;
     }

     .obj{
        bottom: 60px;
        left: 45%;
        width: 360px;
        transform: translateX(-50%);
        max-width: 80%;
     }

     .svgLogo{
       width: 200px;
     }

     .video {
        border: solid 3px $group_dark;
    }

     .pagerow{
       .col-benvPeopleContent{
           .bgrounded{
              background-color: rgba($group_light1, .6);
          }
        }
     }

     svg{
        &.pallogrammaSvg{
          width: 750px;
          max-width: 100%;
          margin: 0 auto;
        }
     }
  }

  &.pharmaPage{
     background-image: linear-gradient(30deg, #a3d8e2 10%, lighten($pharma_col, 20%) 25%, lighten(#a3d8e2, 5%) 50%, lighten($pharma_col, 30%) 75%, #a3d8e2 90%);

     h1, p.quote, p.quoteRole, h2, h3{ color: $pharma_dark; }
     h4{ color: $pharma_col; }
     h5, h6 { color: $pharma_dark; }

     .dnaSvg, .dnawSvg, .pinsSvg, .docSvg,
     .lenteSvg, .cuoSvg, .nuvSvg, .proSvg{ fill:url(#iconlg2);  }

     .tubeSvg{ .path {stroke:url(#tubePharma)} }

     .ball{ background: linear-gradient(90deg, rgba($pharma_light1,1) 0%, rgba($pharma_light2,1) 100%); }
     .svgIcon{ top: 40px; right: 50%; width: 200px;}

     .platSvg{
         margin-top: 50%;
         @include breakpoint(md) {
            margin-top: 200px;
         }
         .ell2{ fill: url(#p2g1); }
         .rectang, .ell1{ fill: url(#p2g2); }
     }

     .estCountries{
        margin-top: 10px;
        font-size: 13px;
        color: $green;
        text-align: left;
     }

     .obj{
        bottom: 13%;
        left: 35%;
        width: 350px;
        transform: translateX(-50%);
        max-width: 80%;

        @include breakpoint(md) {
           bottom: 60px;
           left: 37%;
        }
        @include breakpoint(lg) {
           bottom: 70px;
           left: 40%;
        }
     }

     .svgLogo{
        width: 70%;
        .svgPharmaText {transform:translateY(-5px);}
     }

     .pagerow{
       .col-benvPeopleContent{
           .bgrounded{
             background-color: rgba($pharma_light1, .7);
          }
       }

       &.pagerow_head{
         .title{
            .logo-wrapper{
               background-image: linear-gradient(90deg, $pharma_light1 0%, $pharma_light2 100%);
               .svgLogo{
                 top: calc(50% + 10px);
               }
            }
         }
       }
     }
  }

  &.biotechPage{
     background-image: linear-gradient(30deg, #f3f4e5 0%, #b48cba 25%, #f4e1ae 50%, #b48cba 75%, #f3f4e5 100%);

     h1, h2, h3, p.quoteAuthor, h4, p.quote, p.quoteRole{ color: $biotech_dark; }
     h5, h6 { color: $biotech_dark; }

     .dnaSvg, .dnawSvg, .pinsSvg, .docSvg,
     .lenteSvg, .cuoSvg, .nuvSvg, .proSvg{ fill:url(#iconlg3);  }

     .tubeSvg{ .path {stroke:url(#tubeBiotech)} }

     .ball{ background: linear-gradient(90deg, rgba($biotech_light1,1) 0%, rgba($biotech_light2,1) 100%); }
     .svgIcon{ top: 40px; right: 50%; width: 200px;}

     .platSvg{
         margin-top: 80%;
         @include breakpoint(md) {
           margin-top: 230px;
         }
         .ell2{ fill: url(#p3g1); }
         .rectang, .ell1{ fill: url(#p3g2); }
     }

     .obj{
        width: 260px;
        bottom: 13%;
        left: 50%;
        transform: translateX(-50%);
        max-width: 70%;

        @include breakpoint(md) {
           bottom: 60px;
        }
        @include breakpoint(lg) {
           bottom: 70px;
        }
     }

     .svgLogo{
        width: 78%;
        right: calc(50% + 100px) !important;
     }

     .pagerow{
       .col-benvPeopleContent{
           .bgrounded{
             background-color: rgba($biotech_col, .6);
          }
       }


       &.pagerow_head{
         .title{
            .logo-wrapper{
               background-image: linear-gradient(90deg, $biotech_light1 0%, $biotech_light2 100%);
            }
         }
       }
     }


     .quoteblock{
       border: solid 3px $biotech_dark;
     }
  }

  &.zachPage{
     background-image: linear-gradient(-150deg, #ffffff 0%, lighten($zach_light1, 0%) 55%, #ffffff 100%);

     h1, h2, h3, p.quoteAuthor, p.quote, p.quoteRole{ color: $zach_dark; }
     h4, h5, h6 { color: $zach_dark; }

     .dnaSvg, .dnawSvg, .pinsSvg, .docSvg,
     .lenteSvg, .cuoSvg, .nuvSvg, .proSvg{ fill:url(#iconlg4);  }

     .tubeSvg{ .path {stroke:url(#tubeZach)} }

     .ball{ background: linear-gradient(90deg, rgba($zach_light1,1) 0%, rgba($zach_light2,1) 100%); }
     .svgIcon{ top: 40px; right: 50%; width: 200px;}

     .platSvg{
        margin-top: 60%;
        @include breakpoint(md) {
          margin-top: 230px;
        }
         .ell2{ fill: url(#p4g1); }
         .rectang, .ell1{ fill: url(#p4g2); }
     }

     .obj{
        width: 380px;
        bottom: 13%;
        left: 38%;
        transform: translateX(-50%);
        max-width: 70%;

        @include breakpoint(md) {
           bottom: 60px;
        }
        @include breakpoint(lg) {
           bottom: 70px;
        }
     }

     .svgLogo{ width: 70%; }

      .quoteblock{
         border: solid 3px $zach_col;
      }
      .pagerow{
        .col-benvPeopleContent{
            .bgrounded{
              background-color: rgba($zach_col, .6);
           }
        }

         &.pagerow_head{
            .title{
               .logo-wrapper{
                  background-image: linear-gradient(90deg, $zach_light1 0%, $zach_light2 100%);
               }
            }
         }
      }
  }

  &.openzonePage{
     background-image: linear-gradient(30deg, #ffffff 10%, #6fbcba 25%, rgba($open_dark, .6) 50%, $open_col 75%, #ffffff 90%);

     h1, h2, h3, h4, p.quoteAuthor, p.quote, p.quoteRole{ color: $open_dark; }
     h4 span{ color: $open_dark; }
     h5, h6 { color: $open_dark; }

     .dnaSvg, .dnawSvg, .pinsSvg, .docSvg,
     .lenteSvg, .cuoSvg, .nuvSvg, .proSvg{ fill:url(#iconlg6);  }

     .tubeSvg{ .path {stroke:url(#tubeOpenzone)} }

     .ball{ background: linear-gradient(90deg, rgba($open_col,1) 0%, rgba($open_dark,1) 100%); }
     .svgIcon{ top: 40px; right: 50%; width: 200px;}

     .platSvg{
         margin-top: 60%;
         @include breakpoint(md) {
           margin-top: 230px;
         }
         .ell2{ fill: url(#p5g1); }
         .rectang, .ell1{ fill: url(#p5g2); }
     }

     .ozIcon{
        &.ozIcon1{ width: 120px; }
        &.ozIcon2{ width: 120px; }
        &.ozIcon3{ width: 70px; }
        &.ozIcon4{ width: 80px; }
        &.ozIcon5{ width: 80px; }
        &.ozIcon6{ width: 70px; }
     }

     .obj{
        left: 40%;
        width: 290px;
        bottom: 13%;
        transform: translateX(-50%);
        max-width: 70%;

        @include breakpoint(md) {
           bottom: 60px;
        }
        @include breakpoint(lg) {
           bottom: 70px;
        }
     }

     .svgLogo{ width: 50%; }

     .quoteblock{
       border: solid 3px $open_dark;
     }

     .pagerow{
       .col-benvPeopleContent{
           .bgrounded{
             background-color: rgba($open_dark, .5);
          }
       }

       &.pagerow_head{
          .title{
             .logo-wrapper{
                background-image: linear-gradient(90deg, $open_col 0%, $open_dark 100%);
             }
          }
       }
     }

     .video {
       border: solid 3px $open_dark;
    }

    .swiper{
      border: solid 3px $open_dark;
      .swiper-button-next, .swiper-button-prev{
         color: $open_col;
      }
      .swiper-pagination-bullet{
         border:solid 1px $white;
         opacity: .5;
      }
      .swiper-pagination-bullet-active {
         background: $open_col;
         border:solid 1px $white;
         opacity: 1;
      }
    }

    .credits{
      color: $open_dark;
   }
  }

  &.zcubePage{
     background-image: linear-gradient(30deg, #fefdfd 10%, #9874a8 25%, #b6c9c3 50%, #9874a8 75%, #fefdfd 90%);

     p.quote, p.quoteRole, p{ color: $zcube_col; }
     h1, h2, h3, h4, p.quoteAuthor{ color: $zcube_col; }
     h5, h6 { color: $zcube_col; }

     .dnaSvg, .dnawSvg, .pinsSvg, .docSvg,
     .lenteSvg, .cuoSvg, .nuvSvg, .proSvg{ fill:url(#iconlg5);  }

     .tubeSvg{ .path {stroke:url(#tubeZcube)} }

     .ball{ background: linear-gradient(90deg, rgba(#62bebb,1) 0%, rgba(#0016c3,1) 100%); }
     .svgIcon{ top: 40px; right: 50%; width: 200px;}

     .platSvg{
        margin-top: 60%;
        @include breakpoint(md) {
          margin-top: 230px;
        }
         .ell2{ fill: url(#p6g1); }
         .rectang, .ell1{ fill: url(#p6g2); }
     }

     .quoteblock{
       border: solid 3px $zcube_col;
     }

     .obj{
        width: 300px;
        transform: translateX(-50%);
        left: 40%;
        bottom: 13%;
        max-width: 70%;

        @include breakpoint(md) {
           bottom: 60px;
        }
        @include breakpoint(lg) {
           bottom: 70px;
        }
     }

     .svgLogo{ width: 70%; }

     .borderdImg{
       border: solid 3px $zcube_col;
     }

     .swiper{
       border: solid 3px $zcube_col;
       .swiper-pagination-bullet{
          border:solid 1px $white;
          opacity: .5;
       }
       .swiper-pagination-bullet-active {
          background: $zcube_col;
          border:solid 1px $white;
          opacity: 1;
       }
     }

     .pagerow{

       .col-benvPeopleContent{
           .bgrounded{
             background-color: rgba(#22418e, .3);
             color: $white;
          }
       }
       &.pagerow_head{
          .title{
             .logo-wrapper{
                background-image: linear-gradient(90deg, $zcube_light1 0%, $zcube_light2 100%);
             }
          }
       }
     }

  }

  &.itaPage{
     background-image: linear-gradient(30deg, lighten(#df943b, 10%) 0%, #ababab 35%, rgba(#b6bd23, .5) 60%, lighten(#86bdd0, 1%) 75%, #ffffff 90%);

     p.quote, p.quoteRole{ color: $ita_dark; }
     h1, h2, h3, h4, p.quoteAuthor{ color: $ita_col; }
     h2.care, h3.care{ color: $white;} //$ita_grey; }

     h5, h6 { color: darken($ita_col, 20%); }

     .dnaSvg, .dnawSvg, .pinsSvg, .docSvg,
     .lenteSvg, .cuoSvg, .nuvSvg, .proSvg{ fill:url(#iconlg7);  }

     .tubeSvg{ .path {stroke:url(#tubeIta)} }


     .ball{ background: linear-gradient(90deg, rgba($ita_col,1) 0%, rgba($ita_dark,1) 100%); }
     .svgIcon{ top: 40px; right: 50%; width: 70%; }

     .platSvg{
        margin-top: 60%;
        @include breakpoint(md) {
          margin-top: 230px;
        }
         .ell2{ fill: url(#p7g1); }
         .rectang, .ell1{ fill: url(#p7g2); }
     }

     .obj{
        left: 43%;
        width: 330px;
        transform: translateX(-50%);
        bottom: 13%;
        max-width: 70%;

        @include breakpoint(md) {
           bottom: 60px;
        }
        @include breakpoint(lg) {
           bottom: 70px;
        }
     }

     .logo-wrapper{
        .svgLogo{ width: 200px; }
     }

     .pagerow{
       .col-benvPeopleContent{
           .bgrounded{
             background-color: rgba($ita_col, .5);
             color: $white;

             &.bg2{
                background-color: rgba($ita_dark, .7);
             }
          }
       }

       .hacSvg{
          margin: 0 auto;
          max-width: 300px;
          height: 120px;
       }

       .paSvg{
          margin: 0 auto;
          max-width: 350px;
          height: 120px;
       }
       &.pagerow_head{
          .title{
             padding: 0;
             display: flex;
             flex-direction: column;

             @include breakpoint(md) {
                flex-direction: row;
             }
             .logo-wrapper{
                width: 150px;
                height: 150px;
                max-width: 100%;

                @include breakpoint(sm) {
                   width: 168px;
                   height: 168px;
                }


                @include breakpoint(lg) {
                   width: 224px;
                   height: 224px;
                }

                @include breakpoint(xl) {
                   width: 230px;
                   height: 230px;
                }

                &:first-child{
                   background-image: linear-gradient(90deg, $ita_col 0%, $yellow 100%);
                   .svgLogo{ width: 80%; }
                }

                &:last-child{
                   background-image: linear-gradient(90deg, $ita_grey 20%, $ita_dark 100%);
                   .svgLogo{ width: 80%; }

                   @include breakpoint(md) {
                      margin-left: 20px;
                   }
                }
             }
          }
       }
     }
  }

  &.zoePage{
     background-image: linear-gradient(30deg, #ede5ec 10%, #bc9fbb 25%, lighten(#776d9f, 15%) 50%, #bc9fbb 75%, #ede5ec 90%);

     p.quote, p.quoteRole{ color: $zoe_col; }
     p.quote{
        font-style: italic; font-size: 22px;
        line-height: 30px;
        margin-bottom: 40px;
        &:first-letter{
           padding-left: 40px;
        }
        span{
           position: absolute;
           top: 0px;
           left: 0px;
           font-size: 60px;
           font-weight: 700;

           &.last{
             position: absolute;
             top: auto;
             left: auto;
             bottom: -30px;
             right: auto;
          }
        }
     }

     h1, h2, h3, h4, p.quoteAuthor{ color: $zoe_col; }
     h5, h6 { color: $zoe_col; }

     .dnaSvg, .dnawSvg, .pinsSvg, .docSvg,
     .lenteSvg, .cuoSvg, .nuvSvg, .proSvg{ fill:url(#iconlg8);  }

     .ball{ background: linear-gradient(90deg, rgba($zoe_col, 1) 0%, rgba($zoe_dark, 1) 100%); }


     .tubeSvg{ .path {stroke:url(#tubeZoe)} }

     .video {
       border: solid 3px $zoe_dark;
     }

     .platSvg{
        margin-top: 60%;
        @include breakpoint(md) {
          margin-top: 230px;
        }
         .ell2{ fill: url(#p8g1); }
         .rectang, .ell1{ fill: url(#p8g2); }
     }

     .obj{
        left: 50%;
        width: 260px;
        transform: translateX(-50%);
        bottom: 13%;
        max-width: 70%;

        @include breakpoint(md) {
           bottom: 50px;
        }
        @include breakpoint(lg) {
           bottom: 60px;
        }
     }

     .svgLogo{
        width: 70%;
        .svgPharmaText {transform:translateY(-5px);}
     }

     .quoteblock{
       border: solid 3px $zoe_dark;
     }

     .swiper{
       border: solid 3px $zoe_dark;
       .swiper-pagination-bullet{
          border:solid 1px $white;
          opacity: .5;
       }
       .swiper-pagination-bullet-active {
          background: $zoe_dark;
          border:solid 1px $white;
          opacity: 1;
       }
     }

     .pagerow{
       .col-benvPeopleContent{
           .bgrounded{
             background-color: rgba($zoe_col, .5);
          }
       }

       &.pagerow_head{
          .title{
            .logo-wrapper{
               background-image: linear-gradient(90deg, $zoe_col 0%, $zoe_dark 100%);
            }
          }
       }
     }
  }

  &.pharmaPage{
     .aree{
        display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          @include breakpoint(md) {
            flex-direction: row;
          }

          .area{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 5px;
            flex: 1 1 0px;

            @include breakpoint(md) {
              margin-bottom: 0px;
              flex-direction: column;
            }


            img{
               max-width: 80px;
               margin-right: 20px;
               @include breakpoint(md) {
                  max-width: 110px;
                 margin-right: 0px;
               }
            }
            svg{
              width: 70px;
              flex: 1 0 70px;
              margin-right: 10px;

              @include breakpoint(sm) {
                width: 100px;
                flex: 1 0 100px;
              }

              @include breakpoint(md) {
                margin-right: 0px;
                width: 180px;
                max-width: 100%;
              }
            }

            h4{
              font-size: 18px;
              margin-top: 10px;
              text-align: left;
              font-weight: 400;
              color: #004b78;
              @include breakpoint(md) {
                text-align: center;
              }
            }
          }
       }
       .areeList{
          p{
            position: relative;
            &:before{
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              transform:translate(-100%, 0);
              width: 30px;
              height: 30px;
              background: red;
              border-radius: 50%;
            }

            &:nth-child(1){ &:before{ width: 30px; height: 30px; }}
            &:nth-child(1){ &:before{ width: 30px; height: 30px; }}
            &:nth-child(1){ &:before{ width: 30px; height: 30px; }}
            &:nth-child(1){ &:before{ width: 30px; height: 30px; }}

          }
       }
     .areeTer{
        max-width: 100%;
        @include breakpoint(lg) {
         max-width: 800px;
        }
     }
     .graph_e_nums .num{
        left: calc(50% + 10px);
        top: calc(50% + 10px);
        span{
           color: #004b78;
           font-weight: 600;
           font-size: 50px;
        }
     }
     .pharmaMondo{
        width: 400px;
        .uuid-1fe12f0b-94d7-42b2-8f2c-96863393c7d8 {
         fill: url(#uuid-1add01f4-cb19-4b4c-94ac-eb257a51c6a0);
       }

       .uuid-1fe12f0b-94d7-42b2-8f2c-96863393c7d8, .uuid-a03a94ca-7557-40b4-81a2-7c4aeff4fd4e, .uuid-5b8a550e-ecf2-4e95-b738-a4b1fe2599ca, .uuid-1051111f-c9bb-477a-a7b3-a5be9c08d448, .uuid-2152ce16-cac6-479b-913b-83fe9b7667ba, .uuid-cdfdb7be-4da0-4056-be4e-db60899df2a0, .uuid-75ae1fed-1987-457a-becb-f67eb395ef65 {
         stroke-width: 0px;
       }

       .uuid-a03a94ca-7557-40b4-81a2-7c4aeff4fd4e {
         fill: #297fbe;
       }

       .uuid-5b8a550e-ecf2-4e95-b738-a4b1fe2599ca {
         fill: #1494bb;
       }

       .uuid-1051111f-c9bb-477a-a7b3-a5be9c08d448 {
         fill: #1d8c7e;
       }

       .uuid-2152ce16-cac6-479b-913b-83fe9b7667ba {
         fill: #1d646a;
       }

       .uuid-f10f743c-36c3-42d8-879f-4403fc763b50 {
         opacity: .36;
       }

       .uuid-cdfdb7be-4da0-4056-be4e-db60899df2a0 {
         fill: #1e3f67;
       }

       .uuid-75ae1fed-1987-457a-becb-f67eb395ef65 {
         fill: #008c36;
       }
     }
     .personePharma{
        width: 400px;
        .uuid-1cdcb9f1-b2f4-47e1-8b51-811ee54921b8 {
         fill: #297fbe;
       }

       .uuid-1cdcb9f1-b2f4-47e1-8b51-811ee54921b8, .uuid-58afe2de-1e48-44d7-8923-a47b7826be05, .uuid-f7b51343-876b-4d49-afde-03988c293b64, .uuid-310cdde9-4b23-421b-b6e3-caffeb52376c, .uuid-2a3cb8da-e2a0-4e77-8f82-1d654df69155, .uuid-51bfaf95-2ae4-43ae-8c51-a0f6fb4d1a95, .uuid-bd6c70fd-f238-488a-95e2-9bcf319ef112 {
         stroke-width: 0px;
       }

       .uuid-58afe2de-1e48-44d7-8923-a47b7826be05 {
         fill: #1f4959;
       }

       .uuid-f7b51343-876b-4d49-afde-03988c293b64 {
         fill: #1d8c7e;
       }

       .uuid-310cdde9-4b23-421b-b6e3-caffeb52376c {
         fill: #d8edf3;
         opacity: .3;
       }

       .uuid-2a3cb8da-e2a0-4e77-8f82-1d654df69155 {
         fill: #253561;
       }

       .uuid-51bfaf95-2ae4-43ae-8c51-a0f6fb4d1a95 {
         fill: #1e3f67;
       }

       .uuid-bd6c70fd-f238-488a-95e2-9bcf319ef112 {
         fill: #008c36;
       }
     }
  }
/*
  &.zbiotechPage{

    .zbiorow{
      position: relative;

      &.sky{
        height: 80vh;
        @include sfumatura("sky");

        .svgBiotech{
          width: 700px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -100%);
          path, polygon{
            fill: $white;
          }
        }
      }

      &.zbio1{
        @include sfumatura(0);
        padding-bottom: 250px;

        .row_bg{
          .step{
            opacity: 0;
            animation: step 0.1s linear forwards;

            &:nth-child(1){ animation-delay: 0.5s; }
            &:nth-child(2){ animation-delay: 1.0s; }
            &:nth-child(3){ animation-delay: 1.5s; }
            &:nth-child(4){ animation-delay: 2.0s; }
            &:nth-child(5){ animation-delay: 2.5s; }
            &:nth-child(6){ animation-delay: 3.0s; }
            &:nth-child(7){ animation-delay: 3.5s; }
            &:nth-child(8){ animation-delay: 4.0s; }
            &:nth-child(9){ animation-delay: 4.5s; }
            &:nth-child(10){ animation-delay: 5.0s; }
            &:nth-child(11){ animation-delay: 5.5s; }
            &:nth-child(12){ animation-delay: 6.0s; }
            &:nth-child(13){ animation-delay: 6.5s; }
          }
        }

        .albero{
          &:nth-child(1){
            left: 350px;
            top: -90px;
            .foglie{ fill: $foglie2; }
          }
          &:nth-child(2){
            left: 270px;
            top: -70px;
            .foglie{ fill: $foglie4; }
          }
          &:nth-child(3){
            right: 270px;
            top: -120px;
            .foglie{ fill: $foglie2; }
          }
          &:nth-child(4){
            right: 220px;
            top: -90px;
            .foglie{ fill: $foglie4; }
          }
        }
      }

      &.zbio2{
        padding-bottom: 250px;
        @include sfumatura(0);
      }

      &.zbio3{
        padding-bottom: 250px;
        @include sfumatura(0);

        .albero{
          &:nth-child(1){
            left: 350px;
            top: -90px;
            .foglie{ fill: $foglie2; }
          }
          &:nth-child(2){
            left: 270px;
            bottom: 70px;
            .foglie{ fill: $foglie4; }
          }
          &:nth-child(3){
            left: 200px;
            bottom: 120px;
            .foglie{ fill: $foglie2; }
          }
          &:nth-child(4){
            left: 210px;
            bottom: 30px;
            .foglie{ fill: $foglie4; }
          }
          &:nth-child(5){
            right: 220px;
            bottom: 90px;
            .foglie{ fill: $foglie4; }
          }
          &:nth-child(6){
            right: 270px;
            bottom: 120px;
            .foglie{ fill: $foglie2; }
          }
          &:nth-child(7){
            right: 100px;
            bottom: 30px;
            .foglie{ fill: $foglie4; }
          }
        }
      }



      .row_bg{
        position: absolute;
        top: -190px;
        z-index: 0;
      }
    }
  }

  &.zachPage{

    .zachrow{
      position: relative;

      .row_bg{
        position: absolute;
        top: -190px;
        z-index: 0;
      }

      &.sky{
        height: 80vh;
        @include sfumatura("sky");

        .zachSvg{
          width: 450px;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -100%);
          path, polygon{
            fill: $white;
          }
        }
      }

      &.zach1{
        z-index: 1;
        @include sfumatura(0);
        padding-bottom: 250px;

        .albero{
          &:nth-child(1){
            left: 350px;
            top: -140px;
            .foglie{ fill: $foglie2; }
          }
          &:nth-child(2){
            left: 270px;
            top: -120px;
            .foglie{ fill: $foglie4; }
          }
          &:nth-child(3){
            right: 270px;
            top: 30%;
            .foglie{ fill: $foglie3; }
          }
          &:nth-child(4){
            right: 210px;
            top: 34%;
            .foglie{ fill: $foglie1; }
          }
        }
      }

      &.zach2{
        z-index: 2;
        @include sfumatura(0);
        padding-bottom: 250px;

        .graph_e_nums{
          width: 100%;
        }

        .albero{

          &:nth-child(1){
            left: 270px;
            top: -120px;
            .foglie{ fill: $foglie4; }
          }
          &:nth-child(2){
            right: 270px;
            top: -140px;
            .foglie{ fill: $foglie3; }
          }
          &:nth-child(3){
            right: 210px;
            top: -130px;
            .foglie{ fill: $foglie1; }
          }
        }
      }

      &.zach3{
        z-index: 3;
        @include sfumatura(0);
        padding-bottom: 250px;

        .albero{
          &:nth-child(1){
            left: 350px;
            top: -90px;
            .foglie{ fill: $foglie2; }
          }
          &:nth-child(2){
            left: 270px;
            bottom: 70px;
            .foglie{ fill: $foglie4; }
          }
          &:nth-child(3){
            left: 200px;
            bottom: 120px;
            .foglie{ fill: $foglie2; }
          }
          &:nth-child(4){
            left: 210px;
            bottom: 30px;
            .foglie{ fill: $foglie4; }
          }
          &:nth-child(5){
            right: 220px;
            bottom: 90px;
            .foglie{ fill: $foglie4; }
          }
          &:nth-child(6){
            right: 270px;
            bottom: 120px;
            .foglie{ fill: $foglie2; }
          }
          &:nth-child(7){
            right: 100px;
            bottom: 30px;
            .foglie{ fill: $foglie4; }
          }
        }
      }
    }

  }

  &.zcubePage{

    .zcuberow{
      position: relative;

      &.sky{
        height: 80vh;
        @include sfumatura("sky");

        .zcubeSvg{
          width: 450px;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -100%);
          path, polygon{
            fill: $white;
          }
        }
      }

      &.zcube1{
        @include sfumatura(0);
        padding-bottom: 250px;

        .row{
          align-items: center;
        }
      }

      &.zcube2{
        padding-bottom: 250px;
        @include sfumatura(0);
      }

      &.zcube3{
        padding-bottom: 250px;
        @include sfumatura(0);

        .oaSvg{
          max-width: 350px;
        }
      }

      &.zcube4{
        padding-bottom: 250px;
        @include sfumatura(0);

        .vitaSvg{
          max-width: 300px;
        }
      }

      .row_bg{
        position: absolute;
        top: -190px;
        z-index: 0;
      }
    }
  }

  &.zoePage{

    .zoerow{
      position: relative;

      &.sky{
        height: 80vh;
        @include sfumatura("sky");

        .zoeSvg{
          width: 350px;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -80%);
        }
      }

      &.zoe1{
        padding-bottom: 250px;
        @include sfumatura(0);
      }

      &.zoe2{
        padding-bottom: 250px;
        @include sfumatura(0);
        img.logo_os{
          max-width: 400px;
        }
      }

      &.zoe3{
        padding-bottom: 250px;
        @include sfumatura(0);
      }

      &.zoe4{
        padding-bottom: 250px;
        @include sfumatura(0);
      }

      &.zoe5{
        padding-bottom: 250px;
        @include sfumatura(0);
      }

      &.zoebase{
        min-height: 20vh;
        @include sfumatura(0);
      }

      .row_bg{
        position: absolute;
        top: -190px;
        z-index: 0;
      }
    }
  }

  &.itaPage{

    .itarow{
      position: relative;

      &.sky{
        height: 80vh;
        @include sfumatura("sky");

        svg{

          height: auto;
          position: absolute;


          &.hacSvg{
            width: 350px;
            left: 5%;
            top: 5%;
          }

          &.paSvg{
            width: 450px;
            left: 50%;
            top: 40%;
            transform: translate(-50%, -80%);
          }
          &.svgCareapt{
            width: 350px;
            right: 10%;
            bottom: 30%;
          }
        }
      }

      &.ita1{
        @include sfumatura(0);
        padding-bottom: 250px;
        .hacSvg{
          max-width: 300px;
        }
        .paSvg{
          max-width: 380px;
        }

        .circleia{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-end;

          .circle {
            @include sfumatura('italiassistenza');

            &.circle1{
              width: 270px;
              height: 270px;
            }
            &.circle2{
              width: 230px;
              height: 230px;
            }
          }

          .circleBlock{
            margin-left: auto;
            p{
              margin-top: 10px;
            }
          }
        }

        .circleia2{
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 20px;

          .break {
            flex-basis: 100%;
            height: 0;
          }

          .circle {
            @include sfumatura('italiassistenza');

            &.circle1{
              width: 230px;
              height: 230px;
            }
            &.circle2{
              width: 170px;
              height: 170px;
              margin-left: -30px;
              align-self: flex-start;
            }
            &.circle3{
              width: 150px;
              height: 150px;
              margin-left: -30px;
              z-index: 1;
            }
            &.circle4{
              width: 220px;
              height: 220px;
              margin-left: -10px;
              margin-top: -40px;
            }
            &.circle5{
              align-self: flex-start;
              width: 170px;
              height: 170px;
              margin-top: -10px;

            }
          }
        }
        .circleia3{
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 60px;

          .break {
            flex-basis: 100%;
            height: 0;
          }

          .circle {
            @include sfumatura('italiassistenza');

            &.circle1{
              width: 230px;
              height: 230px;
            }
            &.circle2{
              width: 190px;
              height: 190px;
              margin-left: -30px;
              align-self: flex-start;
              margin-top: -30px;

              z-index: 2;
            }
            &.circle3{
              width: 210px;
              height: 210px;
              margin-left: -30px;
              z-index: 1;
            }
            &.circle4{
              width: 220px;
              height: 220px;
              margin-top: -80px;
            }
          }
        }
      }

      &.ita2{
        padding-bottom: 250px;
        @include sfumatura(0);

        .svgCareapt{
          max-width: 300px;
        }
      }


      .row_bg{
        position: absolute;
        top: -190px;
        z-index: 0;
      }
    }
  }

  &.openPage{

    .openrow{
      position: relative;

      &.sky{
        height: 80vh;
        @include sfumatura("sky");

        .svgOZ{
          position: absolute;
          width: 240px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -70%);
        }
      }

      &.open1{
        @include sfumatura(0);
        padding-bottom: 250px;

        .circleoz{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-end;

          .break {
            flex-basis: 100%;
            height: 0;
          }

          .circle {
            @include sfumatura('openzone');

            &.circle1{
              width: 270px;
              height: 270px;
            }
            &.circle2{
              margin-left: auto;
              width: 230px;
              height: 230px;
            }
            &.circle3{
              margin-left: auto;
              margin-top: 30px;
              width: 270px;
              height: 270px;
            }
            &.circle4{
              margin-left: auto;
              width: 170px;
              height: 170px;
            }
          }
        }
      }

      &.open2{
        @include sfumatura(0);
        padding-bottom: 250px;
      }
      &.open3{
        @include sfumatura(0);
        padding-bottom: 250px;
        img{
          max-width: 800px;
        }
      }
      &.open4{
        @include sfumatura(0);
        padding-bottom: 250px;

        .zpiSvg{
          position: absolute;
          left: -5px;
          top: 340px;
          width: 120px;
          transform: rotate(180deg);
        }

        .text-image{
          display: flex;
          flex-direction: row;

          figure{
            padding-left: 20px;
            flex: 0 0 220px;
          }
        }
      }

      .row_bg{
        position: absolute;
        top: -190px;
        z-index: 0;
      }
    }
  }

  &.pharma0Page{
    @include sfumatura("pharma");

    .quote{
      .lettersIcon{ right: 10px; top: -20px; }
    }
    .dataCol{
      .growingIcon{ left: 50%; top: -20px; }
      .euroIcon{ right: 10px; bottom: -20px; }
    }
    .bdCol{
      .pills2Icon{ left: 200px; top: -25px; }
    }

    .iboCol{
      .iboIcon{ left: 100px; bottom: 10px; }
    }

    .goodscienceCol{
      .goodscienceIcon{ left: 100px; bottom: 10px; }
    }

    .worldBg{
      left: 10px;

    }

  }
*/
  &.benesserePage{
    @include sfumatura("group");
  }

  .swiper{
    width: 100%;
    border-radius: 10px;
  }

  .internaSwiper{
    img{
      width: 100%;
      object-fit: cover;
    }
  }

  .internaSwiper2{
    img{
      width: 100%;
      height: 25vh;
      object-fit: cover;
    }
  }

  .verticalSwiper{
    img{
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .credits{
     font-size: 14px;
  }

  // .sustainabilityBlock{
  //   cursor: pointer;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: flex-end;
  //   margin: 10px 0;
  //   padding: 4px;
  //   background-color: $green_hl;
  //   border-radius: 5px;
  //
  //   > svg{
  //     fill: $white;
  //     flex: 0 0 40px;
  //     margin-right: auto;
  //     margin-left: 10px;
  //   }
  //   > div{
  //     flex-grow: 1;
  //     margin-left: 13px;
  //     align-self: center;
  //
  //     p{
  //       margin-bottom: 5px;
  //       &.big{
  //
  //         font-family: $font-cursive;
  //         font-size: 36px;
  //       }
  //     }
  //   }
  // }
}
