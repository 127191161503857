$font-sans:              "Inter", sans-serif;

//$font-cursive:           "dita", cursive;
$font-light:             200;
$font-regular:           400;
$font-bold:              600;

$font-medium: $font-bold;
$font-normal: $font-regular;
$font-strong: $font-bold;
$font-black: $font-bold;

$font-size-xxs:           14px;
$font-size-xs:            16px;
$font-size-s:             18px;
$font-size-m:             20px;
$font-size-l:             28px;
$font-size-xl:            36px; // USED 23
$font-size-xxl:           62px; // USED 23

$line-height-s:            1em;
$line-height-m:            1.2em;
$line-height-l:            2em;

$letter-spacing-m:         0.00em;
$letter-spacing-l:         0.02em;


@mixin font-format($style) {

  /********* HOME ***********/

  @if $style == 'home_head1' {
    font-family: $font-sans;
    font-size: $font-size-xxl;
    line-height: $line-height-s;
    text-transform: uppercase;
    font-weight: $font-black;

    @include breakpoint(md) {
      font-size: $font-size-huge;
    }
  }

  @if $style == 'home_landing_h2' {
    font-family: $font-sans;
    font-size: $font-size-m;
    font-weight: $font-regular;
    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: $font-size-l;
    }
  }

  @if $style == 'home_cta' {
    font-size: $font-size-xxs;
    font-weight: $font-light;
    text-transform: uppercase;
  }



  /********* MENU ***********/

  @if $style == 'menu_date' {
    font-size: $font-size-xs;
    font-weight: $font-light;
  }

  @if $style == 'menu_lang' {
    font-size: $font-size-xs;
    font-weight: $font-light;
  }


  /*********** GROUP **************/

  @if $style == 'title' {

  }

  @if $style == 'subtitle' {

  }



  /********* FIRST LEVEL ***********/

  // @if $style == 'colTitle' {
  //   font-size: $font-size-l;
  //   font-weight: $font-normal;
  //   text-transform: uppercase;
  //   line-height: $line-height-s;
  //
  //
  //   .num{
  //     font-family: $font-cursive;
  //     font-size: $font-size-xl;
  //     line-height: $line-height-s;
  //   }
  //
  //   @include breakpoint(lg) {
  //     @include breakpointH(md) {
  //       font-size: $font-size-l;
  //
  //       .num{
  //         font-size: $font-size-xxl;
  //       }
  //     }
  //   }
  //
  //   @include breakpoint(xxl) {
  //     @include breakpointH(md) {
  //       font-size: $font-size-xl;
  //     }
  //   }
  // }
  //
  // @if $style == 'colTitleBig' {
  //   font-size: $font-size-l;
  //   font-weight: $font-normal;
  //   text-transform: uppercase;
  //   line-height: $line-height-s;
  //
  //
  //   .num{
  //     font-family: $font-cursive;
  //     font-size: $font-size-xl;
  //     line-height: $line-height-s;
  //   }
  //
  //   @include breakpoint(lg) {
  //     @include breakpointH(md) {
  //       font-size: $font-size-l;
  //
  //       .num{
  //         font-size: $font-size-xxl;
  //       }
  //     }
  //   }
  //
  //   @include breakpoint(xl) {
  //     @include breakpointH(md) {
  //       font-size: $font-size-xl;
  //     }
  //   }
  //
  //   @include breakpoint(xxl) {
  //     @include breakpointH(lg) {
  //       font-size: $font-size-huge;
  //     }
  //   }
  // }
  //
  // @if $style == 'colTitleHuge' {
  //   font-size: $font-size-l;
  //   font-weight: $font-normal;
  //   text-transform: uppercase;
  //   line-height: $line-height-s;
  //
  //   @include breakpoint(lg) {
  //     @include breakpointH(sm) {
  //       font-size: $font-size-xl;
  //     }
  //   }
  //
  //   @include breakpoint(xl) {
  //     @include breakpointH(md) {
  //       font-size: $font-size-xxl;
  //     }
  //   }
  //
  //   @include breakpoint(xxl) {
  //     @include breakpointH(lg) {
  //       font-size: $font-size-huge;
  //     }
  //   }
  // }
  //
  // @if $style == 'colTitleExtra' {
  //   font-family: $font-cursive;
  //   font-size: $font-size-xxl;
  //   text-transform: uppercase;
  //   line-height: $line-height-s;
  // }
  //
  // @if $style == 'colTitleSmall' {
  //   font-size: $font-size-m;
  //   font-weight: $font-normal;
  //   text-transform: uppercase;
  //   line-height: $line-height-s;
  //
  //
  //   .num{
  //     font-family: $font-cursive;
  //     font-size: $font-size-xl;
  //     line-height: $line-height-s;
  //   }
  //
  //   @include breakpoint(lg) {
  //     @include breakpointH(sm) {
  //       font-size: $font-size-l;
  //     }
  //   }
  //
  //   @include breakpoint(xxl) {
  //     @include breakpointH(md) {
  //       font-size: $font-size-l;
  //     }
  //   }
  // }
  //
  //
  //
  // @if $style == 'colTitleCursiveSmall' {
  //   font-family: $font-cursive;
  //   font-size: $font-size-l;
  //   line-height: $line-height-m;
  //   text-transform: uppercase;
  //
  //   @include breakpoint(lg) {
  //     @include breakpointH(sm) {
  //       font-size: $font-size-l;
  //     }
  //   }
  //
  //   @include breakpoint(xxl) {
  //     @include breakpointH(lg) {
  //       font-size: $font-size-l;
  //     }
  //   }
  // }
  //
  // @if $style == 'colTitleCursive' {
  //   font-family: $font-cursive;
  //   font-size: $font-size-l;
  //   line-height: $line-height-s;
  //
  //   @include breakpoint(lg) {
  //     @include breakpointH(sm) {
  //       font-size: $font-size-l;
  //     }
  //   }
  //
  //   @include breakpoint(xxl) {
  //     @include breakpointH(lg) {
  //       font-size: $font-size-xxl;
  //     }
  //   }
  // }

  @if $style == 'colParagraph' {
    font-size: $font-size-xs;
    line-height: $line-height-m;
    font-weight: $font-light;

    @include breakpoint(lg) {
      @include breakpointH(sm) {
        font-size: $font-size-s;
      }
    }

    @include breakpoint(xxl) {
      @include breakpointH(md) {
        font-size: $font-size-m;
      }
    }
  }

  @if $style == 'colParagraphSmall' {
    font-size: $font-size-xxs;
    line-height: $line-height-m;
    font-weight: $font-light;

    @include breakpoint(lg) {
      @include breakpointH(sm) {
        font-size: $font-size-xs;
      }
    }
  }

  @if $style == 'numbers' {
    font-size: $font-size-xxl;
    font-weight: $font-light;
    line-height: $line-height-s;

    @include breakpoint(xxl) {
      font-size: $font-size-huge;
    }

  }

  @if $style == 'smallNumbers' {
    font-size: $font-size-xl;
    font-weight: $font-light;
    line-height: $line-height-s;

    @include breakpoint(xxl) {
      font-size: $font-size-xl;
      font-weight: $font-strong;
    }
  }

  @if $style == 'numbersLabel' {
    font-size: $font-size-s;
    font-weight: $font-strong;
  }

  // @if $style == 'vision' {
  //   font-size: $font-size-l;
  //   font-weight: $font-normal;
  //   font-family: $font-cursive;
  //
  //
  //   @include breakpoint(xxl) {
  //     font-size: $font-size-xl;
  //   }
  //
  //   @include breakpoint(xxxl) {
  //     font-size: $font-size-xxl;
  //   }
  // }

  // @if $style == 'th' {
  //   font-family: $font-cursive;
  //   font-size: $font-size-xl;
  // }

  @if $style == 'td' {
    font-size: $font-size-m;
  }

  @if $style == 'tdsmall' {
    font-size: $font-size-xs;
    line-height: 1em;
    padding-bottom: 8px;
  }

  @if $style == 'tableInfo' {
    font-size: $font-size-xs;
  }

  // @if $style == 'quotesSmall' {
  //   font-family: $font-cursive;
  //   font-size: $font-size-l;
  //   line-height: $line-height-s;
  //   font-weight: $font-light;
  //   text-transform: uppercase;
  // }



  @if $style == 'quotes' {

  }


  // @if $style == 'quoteAuthor' {
  //   font-family: $font-cursive;
  //   font-size: $font-size-l;
  //   line-height: $line-height-s;
  //   font-weight: $font-light;
  // }

  @if $style == 'quoteAuthorBenessere' {
    font-family: $font-sans;
    font-weight: $font-light;
    font-size: $font-size-s;
    line-height: $line-height-s;


    @include breakpoint(xl) {
      font-size: $font-size-m;
    }
  }



  @if $style == 'quoteRole' {
    font-family: $font-sans;
    font-weight: $font-light;
    font-size: $font-size-s;
    line-height: $line-height-s;


    @include breakpoint(xl) {
      font-size: $font-size-m;
    }
  }

  @if $style == 'zonerInd' {
    font-family: $font-sans;
    font-weight: $font-light;
    font-size: $font-size-l;
    line-height: $line-height-s;

    @include breakpoint(xl) {
      font-size: $font-size-xxl;
    }
  }


  /********* SECOND LEVEL ***********/


  /********************/



  @if $style == 'intro' {
    font-size: $font-size-m;
    line-height: $line-height-s;
    font-weight: $font-strong;

    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: $font-size-m;
      line-height: $line-height-s;
    }
  }



  @if $style == 'titles' {
    font-size: $font-size-m;
    line-height: $line-height-s;
    font-weight: $font-strong;

    text-transform: uppercase;
    background-color: $green;
    @include sfumatura(1);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    @include breakpoint(sm) {
      font-size: $font-size-l;
      line-height: $line-height-s;
    }

    @include breakpoint(md) {
      font-size: $font-size-xl;
      line-height: $line-height-s;
    }

    @include breakpoint(lg) {
      font-size: $font-size-l;
      line-height: $line-height-s;
    }
    @include breakpoint(xl) {
      font-size: $font-size-xl;
      line-height: $line-height-s;
    }
  }

  @if $style == 'titles_projects' {
    font-size: $font-size-m;
    line-height: $line-height-s;
    font-weight: $font-strong;

    text-transform: uppercase;
    background-color: $green;
    @include sfumatura(1);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    @include breakpoint(sm) {
      font-size: $font-size-l;
      line-height: $line-height-s;
    }

    @include breakpoint(md) {
      font-size: $font-size-xl;
      line-height: $line-height-s;
    }

    @include breakpoint(lg) {
      font-size: $font-size-m;
      line-height: $line-height-s;
    }
    @include breakpoint(xl) {
      font-size: $font-size-l;
      line-height: $line-height-s;
    }

    @include breakpoint(xxxl) {
      font-size: $font-size-xl;
      line-height: $line-height-s;
    }
  }

  @if $style == 'tusparkTitle' {
    font-size: $font-size-m;

    font-weight: $font-strong;

    @include breakpoint(xxl) {
      font-size: $font-size-l;
    }
  }

  @if $style == 'smallFooter' {
    font-size: $font-size-xs;
    font-weight: $font-normal;

  }

  @if $style == 'labsSub' {
    font-size: $font-size-m;
    font-weight: $font-normal;

  }

  @if $style == 'buttonLabel' {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-normal;

    text-transform: uppercase;
    white-space: nowrap;
  }

  @if $style == 'labsCube' {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-normal;

  }


  @if $style == 'tusparkContent' {
    font-size: $font-size-s;
    line-height: $line-height-l;

    font-weight: $font-normal;

    @include breakpoint(xxl) {
      font-size: $font-size-s;
      line-height: $line-height-l;
    }
  }

  @if $style == 'numbersTitle' {
    font-size: $font-size-l;
    line-height: $line-height-s;
    font-weight: $font-strong;

  }


  @if $style == 'numbersSubtitle' {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-normal;

    text-transform: uppercase;
  }

  @if $style == 'numbersbig' {
    font-weight: $font-strong;

    text-transform: uppercase;

    font-size: $font-size-l;
    line-height: $line-height-s;
    sub{
      font-size: $font-size-m;
    }

    @include breakpoint(md) {
      font-size: $font-size-xl;
      line-height: $line-height-s;
      sub{
        font-size: $font-size-m;
      }
    }

    @include breakpoint(xl) {
      font-size: $font-size-xxl;
      line-height: $line-height-s;
      sub{
        font-size: $font-size-l;
      }
    }
  }

  @if $style == 'slideBuildingsTitle' {
    font-size: $font-size-m;
    line-height: $line-height-s;
    font-weight: $font-strong;

    text-transform: uppercase;
  }

  @if $style == 'slideBuildingsInfo' {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-normal;


    @include breakpoint(md) {
      font-size: $font-size-xs;
      line-height: $line-height-s;
    }
  }

  @if $style == 'slideBuildingsNumberSmall' {
    font-size: $font-size-l;
    line-height: $line-height-s;
    font-weight: $font-strong;

    text-transform: uppercase;
  }

  @if $style == 'slideBuildingsNumberBig' {
    font-size: $font-size-l;
    line-height: $line-height-s;
    font-weight: $font-strong;

    text-transform: uppercase;
    margin-bottom: -7px;


    @include breakpoint(md) {
      font-size: $font-size-xl;
      line-height: $line-height-s;
      margin-bottom: -10px;
    }
  }

  @if $style == 'delucchiText' {
    font-size: $font-size-s;
    line-height: $line-height-l;
    font-weight: $font-normal;


    @include breakpoint(sm) {
      font-size: $font-size-xs;
      line-height: $line-height-s;
    }

    @include breakpoint(md) {
      font-size: $font-size-s;
      line-height: $line-height-l;
    }


    @include breakpoint(lg) {
      font-size: $font-size-s;
      line-height: $line-height-l;
    }

    @include breakpoint(xl) {
      font-size: $font-size-m;
      line-height: $line-height-s;
    }
  }

  @if $style == 'numbersOverlay' {
    font-size: $font-size-m;
    line-height: $line-height-s;
    font-weight: $font-strong;


    background-color: $green;
    text-align: left;
    @include sfumatura(1);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    @include breakpoint(md) {
      font-size: $font-size-m;
      line-height: $line-height-s;
    }

    @include breakpoint(xl) {
      font-size: $font-size-l;
      line-height: $line-height-s;
    }
  }
  @if $style == 'names' {
    font-size: $font-size-s;
    line-height: $line-height-l;
    font-weight: $font-strong;

    @include breakpoint(xl) {
      font-size: $font-size-m;
      line-height: $line-height-s;
    }
  }

  @if $style == 'role' {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-normal;


    @include breakpoint(xl) {
      font-size: $font-size-xs;
      line-height: $line-height-s;
    }
  }

  @if $style == 'caption' {
    font-size: $font-size-m;
    line-height: $line-height-s;
    font-weight: $font-normal;

  }
  @if $style == 'playvideo' {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-strong;

  }

  @if $style == 'extradata' {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-strong;


    @include breakpoint(lg) {
      font-size: $font-size-m;
      line-height: $line-height-s;
    }
    @include breakpoint(xl) {
      font-size: $font-size-m;
      line-height: $line-height-s;
    }
    @include breakpoint(xxl) {
      font-size: $font-size-l;
      line-height: $line-height-s;
      font-weight: $font-strong;
    }
  }

  @if $style == 'extradatalabel' {
    font-size: $font-size-xs;
    line-height: $line-height-s;


    @include breakpoint(xl) {
      font-weight: $font-strong;
    }
  }

  @if $style == 'extra_name' {
    font-family: $font-sans;
    font-weight: $font-strong;
    font-size: $font-size-xs;
    line-height: $line-height-s;

    margin-bottom: 0;
  }
  @if $style == 'extra_role' {
    font-family: $font-sans;
    font-weight: $font-light;
    font-size: $font-size-xs;
    line-height: $line-height-s;

  }

  @if $style == 'talk_name' {
    font-family: $font-sans;
    font-weight: $font-strong;
    font-size: $font-size-xs;
    line-height: $line-height-s;

    text-transform: uppercase;

  }

  @if $style == 'talk_position' {
    font-family: $font-sans;
    font-weight: $font-normal;
    font-size: $font-size-xs;
    line-height: $line-height-s;

  }

  @if $style == 'talk_extract' {
    font-family: $font-sans;
    font-weight: $font-normal;
    font-size: $font-size-xs;
    line-height: $line-height-s;

    text-transform: uppercase;
  }

  @if $style == 'talk_description' {
    font-family: $font-sans;
    font-weight: $font-light;
    font-size: $font-size-xs;
    line-height: $line-height-s;

  }


  @if $style == 'orizmenu' {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-strong;

    text-transform: uppercase;
  }

  @if $style == 'vertmenu' {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-normal;

    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: $font-size-s;
      line-height: $line-height-s;
    }
  }

  @if $style == 'follow' {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-light;

  }


  @if $style == 'zonerlisttile' {
    font-family: $font-sans;
    font-weight: $font-strong;
    font-size: $font-size-s;
    line-height: $line-height-l;

    text-decoration: none;
    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: $font-size-ms;
      line-height: $line-height-s;
    }

    @include breakpoint(xl) {
      font-size: $font-size-m;
      line-height: $line-height-s;
    }
  }


  @if $style == 'zonerlist' {
    font-family: $font-sans;
    font-weight: $font-normal;
    font-size: $font-size-xs;

    text-decoration: none;
    text-transform: uppercase;

    &:hover{
      border-bottom: solid 1px $white;
    }

    @include breakpoint(sm) {
      font-size: $font-size-xs;
    }

    @include breakpoint(xl) {
      font-size: $font-size-s;

    }
  }

  @if $style == 'zonerlistspan' {
    font-family: $font-sans;
    font-weight: $font-normal;
    font-size: $font-size-xs;

    text-decoration: none;
    text-transform: uppercase;

    @include breakpoint(sm) {
      font-size: $font-size-xs;
    }

    @include breakpoint(xl) {
      font-size: $font-size-s;

    }
  }}
