@import "vars_and_mixins.scss";
@import "common.scss";


html {
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

html,
body {
  font-family: $font-sans;
  padding: 0;
  position: relative;
  margin: 0;
  min-height: calc(230vh + 190px);
  //min-height: calc(100vh + 400px);
  /* mobile viewport bug fix */
  // min-height: -webkit-fill-available;

  @include breakpoint(md) {
     min-height: calc(230vh + 190px);
  }

  &.noscroll{
    overflow: hidden;
  }

  // &:before{
  //   content: "NO";
  //   position: fixed;
  //   bottom: 0;
  //   left: 0px;;
  //   font-size: 12px;
  //   z-index: 9999999;
  //   background: red;
  //   padding: 2px 5px;
  //
  //   @include breakpointH(xs) {
  //     content:"H-XS";
  //   }
  //
  //   @include breakpointH(sm) {
  //     content:"H-SM";
  //   }
  //
  //   @include breakpointH(md) {
  //     content:"H-MD";
  //   }
  //
  //   @include breakpointH(lg) {
  //     content:"H-LG";
  //   }
  //
  //   @include breakpointH(xl) {
  //     content:"H-XL";
  //   }
  // }
  //
  // &:after{
  //   content: "NO";
  //   position: fixed;
  //   bottom: 0;
  //   right: 0px;;
  //   font-size: 12px;
  //   z-index: 999999999;
  //   background: red;
  //   padding: 2px 5px;
  //
  //   @include breakpoint(sm) {
  //     content:"SM";
  //   }
  //
  //   @include breakpoint(md) {
  //     content:"MD";
  //   }
  //
  //   @include breakpoint(lg) {
  //     content:"LG";
  //   }
  //
  //   @include breakpoint(xl) {
  //     content:"XL";
  //   }
  //
  //   @include breakpoint(xxl) {
  //     content:"XXL";
  //   }
  //
  //   @include breakpoint(xxxl) {
  //     content:"XXXL";
  //   }
  // }
}

.overlay{
  @include z-index(overlayer);
  position: fixed;
  background-color:rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: none;

  &.open{
    display: block;
  }
}

.CookieConsent{
  .contentCookie{
    font-size: 14px;
    a{
      color: #ffffff !important;
    }
  }
  .buttonsCookie{
    display: flex;
    padding-top: 0px !important;
    justify-content: flex-end;

    @media only screen and (max-width: 480px) {
      width: 100%;
    }

    button{

    }
    button.declineBtnCookie{
      background-color: #222 !important;
      border-radius: 4px !important;
      margin: 15px 15px 15px 5px;
    }
    button.acceptBtnCookie{
      color: #ffffff !important;
      background-color: #789325 !important;
      font-size: 13px;
      padding: 5px 10px 3px 10px !important;
      border-radius: 4px !important;
      margin: 15px 5px;
    }
  }
}
