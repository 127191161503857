@import "vars_and_mixins.scss";

.footer {
  background-color: #3c3c3b;
  position: relative;
  padding-top: 50px;
  z-index: 340;
  width: 100%;
  position: absolute;
  bottom: 0;
  @include breakpoint(md) {
     position: absolute;
     bottom: 0;
  }

  .row{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;


    @include breakpoint(xxl) {
      padding: 0 20px;
    }

  }

  .smallfooter{
     position: absolute;
     top: 0;
     left: 0;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: flex-end;
     width: 100%;
     background-color: $yellow;
     color: $dark-grey;
     padding: 0px 30px;
     height: 50px;
     opacity: 1;

     .logo>svg{
       width: 130px;
     }

     &.fixed{
        opacity: 1;
        position: fixed;
        bottom: 0;
        top: auto;
     }
  }

  .bigfooter{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    padding: 15px 0px;

    p, a{
      color: $white;
      max-width: 480px;
      text-align: left;
      font-size: 16px;
      font-weight: 300;
    }
    a{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }

    .footleft{
      padding-right: 20px;
    }

    .footcenter{
      p{
         margin-bottom: 0;
      }
    }

    .footright{
      text-align: left;
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      @media screen and (min-width: 576px) {
         margin-top: 0;
      }

      > div {
         margin-bottom: 10px;
      }

      > a{
         font-size: 13px;
         margin-bottom: 0;

         span{
            text-decoration: underline;
         }

         &:hover{
            text-decoration: none;
            span{
               text-decoration: none;
            }
         }
      }
    }

    .footbottom{
      margin-top: 5px;
      p{
         font-size: 12px;
         margin-bottom: 0;
      }

      @media screen and (min-width: 576px) {
         margin-top: 20px;
      }
    }


  }

  .donwload{
    margin: 15px 0 0 10px;
    color: $white;
    text-decoration: none;

    span{
      border-bottom: 1px solid $white;
    }

    .downloadSvg{
      width: 18px;
      margin: 0 6px 0px 0;

      .downloadPathSvg{
        fill: $white;
      }
    }

    &:hover{
      .downloadSvg{
        .downloadArrowSvg{
          animation-name: bounceAlpha;
          animation-duration:1.4s;
          animation-iteration-count:infinite;
          animation-timing-function:linear;
        }
      }
    }
  }

  .videoLink{
    margin: 15px 5px 0px 0;
    color: $white;
    text-decoration: none;

    span{
      border-bottom: 1px solid $white;
    }

    .playSvg{
      width: 23px;
      margin: 0 6px 0px 0;

      .playPathSvg{
        fill: #3c3c3b;
      }
    }
  }

  button.archive-btn {
    background: transparent;
    border: 0;
    color: $white;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    .arrowDown{
      width: 8px;
      margin: 0 0 1px 2px;
      transition: transform .4s;
      transform: rotate(90deg);
      .cls-2{
        fill: $white;
      }
    }

    &.open{
      .arrowDown{
        transform: rotate(270deg);
      }
    }
  }

  .archive-content{
    color: $white;
    overflow: hidden;
    transition: height .3s;
    height: 0px;

    &.collapsed{
      height: 30px;
    }
  }

  .socials{
    display: flex;
    flex-direction: row;
    display: flex;
    margin-left: auto;

    a{
      margin-right: 7px;

      &:last-child{
        margin-right: 0px;
      }
    }
  }

  .socialSvg{
    width: 28px;
    .socialPath{
      fill:$white;
   }

    &:hover{
      .socialPath{fill:$green;}
    }
  }
}
